import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { BeatLoader } from 'react-spinners';
import reactStringReplace from 'react-string-replace';
import classNames from 'classnames';

import { 
    getExamSectionsAPI,
    getBookQuestionAPI, 
} from '../apis/ExamsApis';

import QuestionComprehension from './QuestionComprehension';
import QuestionSegments from './QuestionSegments';
import Options from './Options';
import CorrectOption from './CorrectOption';
import Solution from './Solution';

const empty_question = {
  id: '',
  book_id: '',
  exam_id: '',
  exam_section_id: '',
  prev_id: null,
  next_id: null,
  original_question_num: '',
  comprehension_id: '',
  comprehension_title: '',
  question: '',
  question_segments: [],
  question_image: '',
  no_options: '',
  option_a: '',
  option_b: '',
  option_c: '',
  option_d: '',
  option_e: '',
  correct_option: '',
  answer: '',
  answer_image: '',
  solution: '',
  solution_image: '',
  svg_store: {}
}

const exams = [ { id: 1, name: 'CAT'}, {id: 2, name: 'SAT'}, {id: 3, name: 'CLAT'}, {id: 4, name: 'NEET'} ];

const QuestionEditor = ({ questionId }) => {
  const [question, setQuestion] = useState(empty_question);
  const [examSections, setExamSections] = useState([]);
  const [loadComplete, setLoadComplete] = useState(false);
  const [examSectionId, setExamSectionId] = useState('');
  
  useEffect(() => {
      if(questionId) {
        fetchQuestion(questionId);
      } else {
        setQuestion(empty_question);
        setExamSectionId('');
        reloadExamSections('', '')
      }
  }, [questionId])
  
  function reloadExamSections(examId, exam_section_id) {
    if(examId) {
      getExamSectionsAPI(examId).then((response) => {
        if(response.status == 'ok') {
          setExamSections(response.data);
          setExamSectionId(exam_section_id);
        }
      });
    } else {
      setExamSections([]);
    }
  }
  
  function fetchQuestion(questionId) {
    getBookQuestionAPI(questionId).then((response) => {
      if(response.status == 'ok') {
        const q = response.data;
        q.question = fixImageSrc(q.question)
        q.option_a = fixImageSrc(q.option_a)
        q.option_b = fixImageSrc(q.option_b)
        q.option_c = fixImageSrc(q.option_c)
        q.option_d = fixImageSrc(q.option_d)
        q.option_e = fixImageSrc(q.option_e)
        q.answer = fixImageSrc(q.answer)
        q.solution = fixImageSrc(q.solution)
        setQuestion(q);
        reloadExamSections(q.exam_id, q.exam_section_id);
        setLoadComplete(true);
      }
    });
  }
  
  const handleFieldUpdate = (field, value) => {
    const q = { ...question, [field]: value };
    setQuestion(q);
  }
  
  const onExamChange = (exam_id) => {
    handleFieldUpdate('exam_id', exam_id);
    setExamSectionId('');
    reloadExamSections(exam_id, '');
  }
  
  const onExamSectionChange = (exam_section_id) => {
    console.log('onExamSectionChange -> ', exam_section_id);
    handleFieldUpdate('exam_section_id', exam_section_id);
    setExamSectionId(exam_section_id);
  }
  
  const fixImageSrc = (content) => {
    let replacedText;
    replacedText = reactStringReplace(content, /\<img src\=['"](.*?)['"] \/\>/, (match, i) => {
      const url = match.includes('/') ? match : `/api/media/images/${match}`;
      return `<img src='${url}' />`
    });

    return replacedText.join('')
  }
  
  const onSaveOptions = (option_a, option_b, option_c, option_d, option_e, options_image) => {
    setQuestion(q => {
      return {
        ...q, 
        option_a, 
        option_b, 
        option_c, 
        option_d, 
        option_e,
        options_image
      }
    })
  }
  
  const onSaveSolution = (solution, solution_image) => {
    setQuestion(q => {
      return {
        ...q, 
        solution,
        solution_image
      }
    })
  }
  
  const onSaveCorrectOption = (correct_option) => {
    setQuestion(q => {
      return {
        ...q, 
        correct_option,
      }
    })
  }
  
  const onChangeComprehension = (comprehension_id, comprehension_title) => {
    setQuestion(q => {
      return {
        ...q, 
        comprehension_id,
        comprehension_title
      }
    })
  }
  
  const getBookLink = (question) => {
    return <a className='book-link' href={`/book/${question.book_id}?question_id=${question.id}`} target="_blank">View in Book</a>
  }
  
  if(!loadComplete) {
    return <div className={classNames('main-loader-container')}>
      <BeatLoader 
        color="#22AA22"
        loading={true}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  }
  
  return (
    <div className='qeditor'>
      <div 
        className={classNames('qeditor__header')}
      >Question ({question.id}){question.book_id && getBookLink(question)}</div>
      <div 
        className={classNames('qeditor__metadata')}
      >
        <div 
          className={classNames('qeditor__metadata__field')}
        >
          <label>EXAM</label>
          <select
            value={question.exam_id}
            onChange={(e) => onExamChange(e.target.value)}
            disabled="disabled"
          >
          <option key={'exam_select'} value={''}>Select</option>
          { exams.map(e => <option key={e.id} value={e.id}>{e.name}</option>) }
          </select>
        </div>
        <div 
          className={classNames('qeditor__metadata__field')}
        >
          <label>SECTION</label>
          <select
            value={examSectionId}
            onChange={(e) => onExamSectionChange(e.target.value)}
            style={{ minWidth: "350px" }}
            disabled="disabled"
          >
          <option key={'examsection_select'} value={''}>Select</option>
          { examSections.map(es => <option key={es.id} value={es.id}>{es.name}</option>) }
          </select>
        </div>
        <div 
          className={classNames('qeditor__metadata__field')}
        >
          <label>ORIGINAL QUESTION NUMBER</label>
          <input
            value={ question.original_question_num }
            onChange={ (e) => handleFieldUpdate('original_question_num', e.target.value) }
            disabled="disabled"
          />
        </div>
      </div>
      <div className={classNames('qeditor__header')}>COMPREHENSION PASSAGE</div>
      <QuestionComprehension 
        question={question} 
        onChange = {onChangeComprehension} 
      />
      
      <div className={classNames('qeditor__header')}>QUESTION</div>
      <QuestionSegments questionId={question.id} segments={question.question_segments} />
      
      <div className={classNames('qeditor__header')}>OPTIONS</div>
      <Options questionId={question.id} question={question} onSave={onSaveOptions} />
      
      <div className={classNames('qeditor__header')}>CORRECT OPTION</div>
      <CorrectOption questionId={question.id} question={question} onSave={onSaveCorrectOption} />
      
      <div className={classNames('qeditor__header')}>SOLUTION</div>
      <Solution questionId={question.id} question={question} onSave={onSaveSolution} />
      
    </div>
  )
};

export default QuestionEditor;