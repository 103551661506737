import { useState, useRef, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { 
    saveQuestionSegmentAPI,
    deleteQuestionSegmentAPI,
} from '../apis/ExamsApis';

const QuestionSegment = ({questionId, segment, svg_store, onSave, onDelete, isDeletable}) => {
  const [currentView, setCurrentView] = useState('text');
  const [segmentText, setSegmentText] = useState(segment.text);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const imageRef = useRef(null);
  
  const textAreaRef = useRef(null);
  const [triggerTextAreaAdjust, setTriggerTextAreaAdjust] = useState(true);
  
  useEffect(() => {
    textAreaAdjust(textAreaRef.current);
  }, [triggerTextAreaAdjust])
  
  function textAreaAdjust(element) {
    element.style.height = "1px";
    if(element.scrollHeight > 100) {
      element.style.height = element.scrollHeight+"px";
    } else {
      element.style.height = "100px";
    }
  }
  
  useEffect(() => {
    if(segment.image) {
      setCurrentView('image');
    } else {
      setCurrentView('text');
    }
  }, [segment.image])
  
  function saveAsText() {
    setIsSaving(true);
    if(segmentText.trim()) {
      const data = { ...segment, text: segmentText, image: null };
      saveQuestionSegmentAPI(questionId, data).then((response) => {
        if(response.status == 'ok') {
          const isNew = segment.id ? false : true;
          onSave(response.data, isNew);
          setIsSaving(false);
          setIsEdited(false);
        }
      });
    }
  }
  
  async function saveAsImage() {
    setIsSaving(true);
    const imageData = await htmlToImage.toPng(imageRef.current);
    console.log(' saveAsImage questionId:', questionId)
    const data = { ...segment, text: segmentText, image: null, image_data: imageData };
    saveQuestionSegmentAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        const isNew = segment.id ? false : true;
        onSave(response.data, isNew);
        setIsSaving(false);
        setCurrentView('image');
        setIsEdited(false);
      }
    });
  }
  
  function onCancel() {
    setSegmentText(segment.text);
    setTriggerTextAreaAdjust(flag => !flag);
    setIsEdited(false);
  }
  
  function onChangeText(text) {
    setSegmentText(text);
    setIsEdited(text != segment.text);
    setTriggerTextAreaAdjust(flag => !flag);
  }
  
  function onDeleteClick () {
    deleteQuestionSegmentAPI(questionId, segment).then((response) => {
      if(response.status == 'ok') {
        onDelete(segment.id);
      }
    });
  }
  
  const fixSvgInContent = (content) => {
    let replacedContent;
    content = content.trim().replaceAll("\n", "<br />")
    replacedContent = reactStringReplace(content, /(\<svg src\=['"].*?['"] \/\>)/, (match, i) => {
      let filePath = match.match(/\<svg src\=['"](.*?)['"] \/\>/).pop()
      const fileName = filePath.split('/').pop();
      if(svg_store.hasOwnProperty(fileName)) {
        return svg_store[fileName];
      } else {
        return match;
      }
    });
    return replacedContent.join('')
  }
  
  
  function getHtml() {
    return <p dangerouslySetInnerHTML={{__html: fixSvgInContent(segmentText.replace(/(?:\r\n|\r|\n)/g, '<br>'))}} ></p>
  }
  
  
  return (
    <div className='seg'>
      <div className={classNames('seg__left')}>
        <div className={classNames('seg__left__btns')}>
            <button onClick={() => setCurrentView('text')} disabled={ (currentView == 'text' || isSaving) ? true : false}>View as Text</button>
            <button onClick={() => setCurrentView('html')} disabled={ (currentView == 'html' || isSaving || !segmentText) ? true : false}>View as HTML</button>
            <button onClick={() => setCurrentView('image')} disabled={ (currentView == 'image' || !segment.image ) ? true : false}>View as Image</button>
        </div>
        <div className={classNames('seg__left__view', currentView=='text' ? 'show' : 'hide')}>
          <textarea
            ref={textAreaRef}
            value={ segmentText }
            onChange={ (e) => onChangeText(e.target.value) }
            onKeyUp={ (e) => textAreaAdjust(e.target) }
          />
        </div>
        <div 
          className={classNames('seg__left__view', currentView=='image' ? 'show' : 'hide')}
        >
          <img className={classNames('seg__left__view__image')} src={segment.image ? `/api/media/images/${segment.image}`: ``} alt='not available' />
        </div>
        <div 
          className={classNames('seg__left__view', currentView=='html' ? 'show' : 'hide')}
          ref={imageRef}
        >
          <div className={classNames('seg__left__view__display')}>
            { currentView=='html' ? getHtml() : '' }
          </div>
        </div>
      </div>
      <div 
        className={classNames('seg__right')}
      >
        { (currentView == 'text' || currentView == 'html') && <div>
            { currentView == 'text' && <button onClick={() => saveAsText()} disabled={ (isSaving || segmentText == segment.text) ? true : false}>Save as Text</button> }
            { currentView == 'html' && <button onClick={() => saveAsImage()} disabled={ (isSaving || (segmentText == segment.text && segment.image)) ? true : false}>Save as Image</button> }
          </div>
        }
        { (segment.id || isEdited) && <div>
            { segment.id && <button onClick={() => onDeleteClick()} disabled={!isDeletable || isSaving || isEdited ? true : false}>Delete this segment</button> }
            <button onClick={() => onCancel()} disabled={ !isEdited || isSaving ? true : false}>Cancel</button>
          </div>
        }
        { isSaving && <div>Saving <BeatLoader 
            color="#22AA22"
            loading={true}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> Please wait!</div>
        }
      </div>
    </div>
  );
};

export default QuestionSegment;
