import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
    Routes,
    Route,
    Link,
} from "react-router-dom";

import { useCookies } from 'react-cookie';

import PDFBooks from './components/PDFBooks';
import DailyExams from './components/DailyExams';
import LiveExams from './components/LiveExams';
import PDFBook from './components/PDFBook';
import WhatsApp from './components/WhatsApp';
import Login from './components/Login';
import Logout from './components/Logout';
import Test from './components/Test';
import Candidate from './components/Candidate';
import './style.scss';

import { 
    setTokenAPI
} from './apis/ExamsApis';


function App() {
  const [cookies, setCookie] = useCookies(['token']);
  
  console.log("cookies.token:", cookies.token);
  
  useEffect(() => {
      setTokenAPI(cookies.token);
  }, [cookies.token])
  
  if(!cookies.token) {
    return <Login />
  }
  
  return (
    <Router>
      <div className='app'>
        <div className='content'>
          <Routes>
              <Route
                  path="/logout"
                  element={<Logout />}
              ></Route>
              <Route
                  path="/whatsapp"
                  element={<WhatsApp />}
              ></Route>
              <Route
                  path="/book/:id"
                  element={<PDFBook />}
              ></Route>
              <Route
                  path="/books"
                  element={<PDFBooks />}
              ></Route>
              <Route
                  path="/dailyexams"
                  element={<DailyExams />}
              ></Route>
              <Route
                  path="/liveexams"
                  element={<LiveExams />}
              ></Route>
              <Route
                  path="/candidate"
                  element={<Candidate />}
              ></Route>
              <Route
                  path="/test"
                  element={<Test />}
              ></Route>
              <Route
                  path="/"
                  element={<PDFBooks />}
              ></Route>
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
