import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { 
    getQuestionSetsAPI,
    getExamsAPI,
    createQuestionSetAPI,
    deleteQuestionSetAPI,
} from '../apis/ExamsApis';

import QuestionSet from './QuestionSet';

const QuestionSets = ({ examId, questionSetId, onQuestionSetIdChange, onQuestionSetUpdated, onClose }) => {
  const [questionSets, setQuestionSets] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [showEditItemView, setShowEditItemView] = useState(false);
  
  const loadQuestionSets = () => {
    getQuestionSetsAPI(examId, questionSetId).then((response) => {
      if(response.status == 'ok') {
        const questionSets = response.data.map(qs => {
          return { ...qs, selected: false };
        });
        setQuestionSets(questionSets);
        setLoadComplete(true);
      }
    });
  }
  
  useEffect(() => {
    setQuestionSets([]);
    if(examId) {
      loadQuestionSets();
    }
  }, [examId])
  
  const onCreateQuestionSet = (item) => {
    const questionSet = { ...item, selected: false };
    setQuestionSets(questionSets => {
      return [...questionSets, questionSet];
    });
  }
  
  const questionSetUpdated = (item) => {
    loadQuestionSets();
    onQuestionSetUpdated();
  }
  
  const onSelectItem = (dailyexam_id, selected) => {
    setQuestionSets(questionSets => {
      return questionSets.map(qs => {
        if(qs.id == dailyexam_id) {
          return { ...qs, selected: selected };
        } else {
          return qs;
        }
      });
    });
  };
  
  const onCreateNewQuestionSetClick = () => {
    const data = {
      exam_id: examId
    };
    createQuestionSetAPI(data).then((response) => {
      if(response.status == 'ok') {
        const eqs = response.data;
        setQuestionSets(questionSets => [ eqs, ...questionSets]);
        onQuestionSetIdChange(eqs.id);
      }
    });
  };
  
  const onQuestionSetRowClick = (eqs_id) => {
    onQuestionSetIdChange(eqs_id);
  };
  
  const onDeleteQuestionSet = (questionSetId) => {
    deleteQuestionSetAPI(questionSetId).then((response) => {
      if(response.status == 'ok') {
        onQuestionSetIdChange('');
        setQuestionSets( questionSets => questionSets.filter(qs => qs.id != questionSetId));
      }
    });
  };
  
  return (
    <div 
      className={classNames('content-body')}
      style={{ width: '96vw', height: '80vh' }}
    >
      <span onClick={() => onClose()} className={classNames('close-button')}>+</span>
      <div 
        className={classNames('block')}
        style={{ borderRightStyle: 'solid 2px #222', flex: 'unset' }}
      >
        <div
          style={{ 'overflowY': 'scroll' }}
        >
          <table 
            className={classNames('items', 'highlight')}
            style={{ 'width': '100%' }}
          >
            <thead>
              <tr>
                <th>Question Set ID</th>
                <th>Num. Questions</th>
                </tr>
            </thead>
            <tbody>
              { questionSets.map(eqs => {
                  return <tr 
                    key={eqs.id}
                    onClick={() => onQuestionSetRowClick(eqs.id)}
                    className={classNames('highlight-hr', eqs.id == questionSetId ? { selectedhr: true }: {})}
                  >
                    <td>{eqs.id}
                      {eqs.id == questionSetId && <><br/><a 
                        onClick={() => onDeleteQuestionSet(eqs.id)}
                        className={classNames('delete-button')}
                      >Delete</a></>}</td>
                    <td>{eqs.num_questions}
                    </td>
                  </tr>
                }
              )}
            </tbody>
            { questionSets.length == 0 && <tfoot><tr><td colSpan='100%'>no question sets</td></tr></tfoot> }
          </table>
        </div>
        <div
          style={{ textAlign: 'center', padding: '10px', marginBottom: '-15px', marginRight: '17px', backgroundColor: '#e1e1e1' }}
        >
          <button 
            onClick={() => onCreateNewQuestionSetClick()}
            className={classNames('button-80')}
            role="button"
          >Create New Question Set</button>
        </div>
      </div>
      <div 
        className={classNames('block')}
        style={{ 
          backgroundColor: '#dbe4e7',
          margin: '10px',
          borderRadius: '12px 12px'
        }}
      >
        <QuestionSet
          questionSetId={questionSetId}
          examId={examId}
          questionSetUpdated={questionSetUpdated}
        />
      </div>
    </div>
  );
};

export default QuestionSets;