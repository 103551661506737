import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';

import { 
    searchQuestionsAPI,
    addQuestionSetQuestionsAPI,
    getExamSectionsAPI,
} from '../apis/ExamsApis';

import PopupContainer from './PopupContainer';
import Question from './Question';

const QuestionSearch = ({ examId, questionSetId, existingQuestions, onAddQuestions, onClose }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  
  const [questionId, setQuestionId] = useState('');
  const [examSectionId, setExamSectionId] = useState('');
  const [examSections, setExamSections] = useState([]);

  const [searchTerm, setSearchTerm] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [loadMore, setLoadMore] = useState(false);
  
  const [isAdding, setIsAdding] = useState(false);
  
  const [showQuestionView, setShowQuestionView] = useState(false);

  const loadExamSections = (examId) => {
    getExamSectionsAPI(examId).then((response) => {
      if(response.status == 'ok') {
        setExamSections(response.data);
      }
    });
  }
  
  const searchQuestions = () => {
    setIsLoading(true);
    setPageNumber(1);
    setLoadMore(false);
    searchQuestionsAPI(examId, examSectionId, searchTerm, 1).then((response) => {
      if(response.status == 'ok') {
        const questions = response.data.map(q => {
          const existing = existingQuestions.findIndex(eq => eq.question_id == q.id) > -1;
          const selected = existing;
          return { ...q, selected: selected, existing: existing };
        });
        setQuestions(questions);
        setIsLoading(false);
        setLoadComplete(true);
        if(questions.length == 20) {
          setLoadMore(true);
        }
      }
    });
  }

  const loadMoreQuestions = () => {
    setIsLoading(true);
    searchQuestionsAPI(examId, examSectionId, searchTerm, pageNumber + 1).then((response) => {
      if(response.status == 'ok') {
        const questions2 = response.data.map(q => {
          const existing = existingQuestions.findIndex(eq => eq.question_id == q.id) > -1;
          const selected = existing;
          return { ...q, selected: selected, existing: existing };
        });
        setQuestions(questions => [...questions, ...questions2]);
        setIsLoading(false);
        setPageNumber(pageNumber + 1);
        if(questions2.length == 20) {
          setLoadMore(true);
        } else {
          setLoadMore(false);
        }
      }
    });
  }


  useEffect(() => {
      setQuestions([]);
      setExamSectionId('');
      if(examId) {
        loadExamSections(examId);
      }
  }, [examId])
  
  
  const onSelectQuestion = (questionId, selected) => {
    setQuestions(
      questions => questions.map(
        q => {
          if(q.id == questionId) {
            return { ...q, selected: selected };
          } else {
            return { ...q };
          }
        }
      )
    );
  }
  
  const onQuestionRowClick = (questionId) => {
    setQuestionId(questionId);
    setShowQuestionView(true);
  }
  
  const onCreateButtonClick = () => {
    setQuestionId('');
    setShowQuestionView(true);
  }
  
  const onSearchButtonClick  = () => {
    setQuestions([]);
    searchQuestions();
  }
  
  const onChangeExamSectionId = (es_id) => {
    setExamSectionId(es_id);
  }
  
  const onAddButtonClick = () => {
    setIsAdding(true);
    const questionIds = questions.filter(q => q.selected == true && existingQuestions.findIndex(eq => eq.question_id == q.id) == -1).map(q => q.id);
    const data = {
      'question_ids': questionIds
    }
    addQuestionSetQuestionsAPI(questionSetId, data).then((response) => {
      if(response.status == 'ok') {
        setQuestions(questions => questions.map(q => {
          if(questionIds.includes(q.id)) {
            return { ...q, existing: true };
          } else {
            return { ...q };
          }
        }));
        onAddQuestions(response.data);
        setIsAdding(false);
      }
    });
  }
  
  const short_text = (text) => {
    if(text.length > 500) {
      return text.substring(0, 500) + "..."
    } else {
      return text
    }
  }
  
  const toFooter = (content) => {
    if(content) {
      return <tfoot><tr><td colSpan='100%'>{content}</td></tr></tfoot> 
    } else {
      return null;
    }
  }
  
  const getFooter = (isLoading, numOfQuestions) => {
    if(isLoading) {
      return toFooter('please wait...');
    } else {
      if(numOfQuestions == 0) {
        return toFooter('no results');
      } else if(loadMore) {
        return toFooter(
          <div
            style={{ textAlign: 'right' }}
          >
            <button
              style={{ alignSelf: 'right' }}
              onClick={() => loadMoreQuestions()}
            >Load More</button>
          </div>
        )
      } else {
        return toFooter("");
      }
    }
  }
  
  return (
    <div 
      className={classNames('content-body')}
      style={{ width: '100vw', height: '100vh' }}
    >
      <div 
        className={classNames('block')}
        style={{ borderRightStyle: 'solid 2px #222' }}
      >
        <div
          className={classNames('search')}
        >
          <div>
            <span>Exam Section </span>
            <select
              value={examSectionId}
              onChange={(e) => onChangeExamSectionId(e.target.value)}
            >
            <option key={'examsection_select'} value={''}>Select</option>
            { examSections.map(es => <option key={es.id} value={es.id}>{es.name}</option>) }
            </select>
            <input
              value={ searchTerm }
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button 
              onClick={() => onSearchButtonClick()}
              className={classNames('button-80')}
              role="button"
            >Search</button>
          </div>
          <a onClick={onCreateButtonClick} className={classNames('link-button', 'float-right')}>Create new question</a>
        </div>
        <div
          className={classNames('search-results')}
          style={{ 'overflowY': 'scroll', flex: '1' }}
        >
          <table 
            className={classNames('items', 'highlight')}
            style={{ 'width': '100%' }}
          >
            <thead>
              <tr>
                <th></th>
                <th>Question ID</th>
                <th>Section</th>
                <th>Question</th>
                <th>Options</th>
                <th>Correct Option / Answer</th>
              </tr>
            </thead>
            <tbody>
              { questions.map(q => {
                  return <tr 
                    key={q.id}
                    onClick={() => onQuestionRowClick(q.id)}
                    className={classNames('highlight-hr', q.selected ? { selectedhr: true }: {})}
                  >
                    <td
                      onClick={(e) => {
                        onSelectQuestion(q.id, !q.selected);
                        e.stopPropagation();
                      }}
                    >
                      <input 
                        type="checkbox" 
                        onChange={(e) => {
                          onSelectQuestion(q.id, !q.selected);
                          e.stopPropagation();
                        }}
                        checked={q.selected}
                        disabled={q.existing}
                        title={ q.existing ? 'already added' : '' }
                      />
                    </td>
                    <td>{q.id}</td>
                    <td>{q.section_name}</td>
                    <td>{short_text(q.question)}</td>
                    <td><pre>{short_text(q.options)}</pre></td>
                    <td>{q.answer}</td>
                  </tr>
                }
              )}
            </tbody>
            { getFooter(isLoading, questions.length) }
          </table>
        </div>
        <div
          style={{ textAlign: 'center', padding: '10px', backgroundColor: '#e1e1e1', marginBottom: '17px' }}
        >
          <button 
            onClick={() => onAddButtonClick()}
            className={classNames('button-80')}
            role="button"
            disabled={isAdding}
          >{ isAdding ? 'Adding...' : 'Add to question set'}</button>
          <button 
            onClick={onClose}
            className={classNames('button-80')}
            role="button"
          >Close</button>
        </div>
      </div>
      
      <PopupContainer
        visible={showQuestionView}
        onClose={() => {
          setShowQuestionView(false);
        }}
        content={
          <Question
            examId={examId}
            questionId={questionId}
            onCreate={(nq) => {
              setQuestions(
                questions => [...questions, nq]
              )
              setQuestionId(nq.id);
            }}
            onUpdate={(uq) => {
              setQuestions(
                questions => questions.map(
                  q => {
                    if(q.id == uq.id) {
                      return { ...q, ...uq };
                    } else {
                      return { ...q };
                    }
                  }
                )
              );
            }}
            onClose={() => {
              setShowQuestionView(false);
            }}
          />
        }
      />
    </div>
  );
};

export default QuestionSearch;
