import { useState, useEffect, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import classNames from 'classnames';

import { 
     getCandidateAPI,
     sendWhatsAppMessageAPI
} from '../apis/ExamsApis';

const empty_candidate = {
  id: '',
  name: '',
  phone_number: ''
}


const Candidate = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [candidate, setCandidate] = useState(empty_candidate);
  const [messages, setMessages] = useState([]);
  const [messageText, setMessageText] = useState('');
  const [statusText, setStatusText] = useState('');
  const [isSending, setIsSending] = useState(false);
  
  const bottomEndRef = useRef(null);
  const [scrollNumber, setScrollNumber] = useState(0);
  
  const loadCandidate = (candidateId) => {
    getCandidateAPI(candidateId).then((response) => {
      if(response.status == 'ok') {
        setCandidate(response.data.candidate);
        setMessages(response.data.messages);
        setScrollNumber(n => n + 1);
      }
    });
  }
  
  useEffect(() => {
    const candidateId = searchParams.get('id');
    if(candidateId) {
      loadCandidate(candidateId);
    }
  }, [])
  
  useEffect(() => {
    if(scrollNumber && bottomEndRef.current) {
      bottomEndRef.current.scrollIntoView();
    }
  }, [scrollNumber])
  
  const onSendButtonClick = () => {
    if(!messageText.trim()) {
      return;
    }
    const data = {
      'candidate_id': candidate.id,
      'message_text': messageText
    };
    setStatusText('Sending...');
    setIsSending(true);
    sendWhatsAppMessageAPI(data).then((response) => {
      if(response.status == 'ok') {
        setStatusText('Message Sent');
        setMessageText("");
        const newMessage = response.data.message;
        setMessages(messages => { return [ ...messages, newMessage ]});
        setScrollNumber(n => n + 1);
      } else {
        setStatusText(response.data.error_message);
      }
      setIsSending(false);
    });
  };
  
  const formatMessage = (message) => {
    return message.split("\n").map(text => <div>{text}</div>);
  }
  
  return (
      <div 
        className={classNames('center', 'full', 'column')}
      >
        <div 
          className={classNames('left', 'column')}
          style={{
            height: '100vh'
          }}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              backgroundColor: '#67938d',
              color: '#FFFFFF' 
            }}
          ><b>{candidate.name}</b></div>
          <div
            style={{
              width: '502px',
              flex: 1,
              overflowY: 'scroll',
            }}
          >
            { messages.map(cm => {
              return <div
                key={cm.id}
                style={{
                  textAlign: 'left',
                }}
              >
                <div><b>{cm.direction == 'IC' ? candidate.name : 'ExamTrainer'}: </b></div>
                <div 
                  style={{
                    border: '1px solid #68929b54',
                    marginBottom: '5px',
                    borderRadius: '5px',
                    padding: '4px',
                  backgroundColor:  cm.direction == 'IC' ? '#ebf5e4' : '#fff4e7'
                  }}
                >{formatMessage(cm.message)}</div>
              </div>
            })}
            <div ref={bottomEndRef}></div>
          </div>
          <div
            style={{
              width: '100%',
            }}
          ><textarea
              style={{
                width: '100%',
              }}
              rows={10}
              value={ messageText }
              onChange={ (e) => {
                if(statusText) {
                  setStatusText('');
                }
                setMessageText(e.target.value);
              }}
            />
          </div>
          <div
              style={{
                width: '100%',
                marginBottom: '10px',
                textAlign: 'right'
              }}
          >
            <label style={{marginRight: '15px'}}>{statusText}</label>
            <button 
              className={classNames('button-80')}
              onClick={onSendButtonClick} 
              disabled={isSending} 
            >Send Message</button>
          </div>
        </div>
      </div>
  );
};

export default Candidate;
