import React, { useEffect } from 'react';

import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';

import { 
    setTokenAPI
} from '../apis/ExamsApis';

export default function Logout() {
  const [cookies, setCookie] = useCookies(['token']);
  
  const navigate = useNavigate();
  
  useEffect(() => {
      setCookie('token', '');
      setTokenAPI();
      navigate(`/`);
  }, [])
  
  return <div>Session expired...</div>
}