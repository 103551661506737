import { useState, useRef, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import ComprehensionSegments from './ComprehensionSegments';

import { 
    getComprehensionAPI,
    saveComprehensionAPI,
    getExamSectionsAPI
} from '../apis/ExamsApis';

const empty_segment = {
  'id': '',
  'text': '',
  'image': ''
}

const Comprehension = ({ comprehensionId, comprehensionTitle, examSectionId, bookId, onSave, onClose }) => {
  const [title, setTitle] = useState(comprehensionTitle);
  const [segments, setSegments] = useState([]);
  const [loadComplete, setLoadComplete] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  
  useEffect(() => {
    if(comprehensionId) {
      fetchComprehension(comprehensionId)
    } else {
      setTitle('');
      setSegments([]);
    }
  }, [comprehensionId])
  
  function fetchComprehension(comprehensionId) {
    getComprehensionAPI(comprehensionId).then((response) => {
      if(response.status == 'ok') {
        const c = response.data;
        setTitle(c.title);
        setSegments(c.segments);
        setLoadComplete(true);
      }
    });
  }
  
  function save() {
    setIsSaving(true);
    if(title.trim()) {
      const data = { id: comprehensionId, title, exam_section_id: examSectionId, book_id: bookId };
      saveComprehensionAPI(data).then((response) => {
        if(response.status == 'ok') {
          onSave(response.data.id, title);
          setIsSaving(false);
          setIsEdited(false);
        }
      });
    }
  }
  
  function onCancel() {
    onClose();
  }
  
  function onChangeTitleText(text) {
    setTitle(text);
    setIsEdited(text != comprehensionTitle);
  }
    
  return (
    <div className='qeditor' style={{ margin: 'initial' }}>
      <div className='seg'>
        <div className={classNames('seg__left')}>
          <div className={classNames('seg__left__view')}>
            <div>
              Passage title: 
              <input
                value={ title }
                onChange={ (e) => onChangeTitleText(e.target.value) }
                style={{ width: '300px', marginLeft: '5px' }}
              />
            </div>
          </div>
        </div>
        <div 
          className={classNames('seg__right')}
          style={{ minWidth: '200px' }}
        >
          <div>
            <button onClick={() => save()} disabled={ (isSaving || !isEdited) ? true : false}>Save</button>
          </div>
          { isEdited && <div>
              { isEdited && <button onClick={() => onCancel()} disabled={ isSaving ? true : false}>Cancel</button> }
            </div>
          }  
          { isSaving && <div>Saving <BeatLoader 
              color="#22AA22"
              loading={true}
              size={5}
              aria-label="Loading Spinner"
              data-testid="loader"
            /> Please wait!</div>
          }
        </div>
      </div>
      
      { comprehensionId && <ComprehensionSegments key={comprehensionId} comprehensionId={comprehensionId} segments={segments} /> }
      
    </div>
  );
};

export default Comprehension;
