import { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";

import classNames from 'classnames';

import PopupContainer from './PopupContainer';
import Question from './Question';

const Test = () => {
  
  let [searchParams, setSearchParams] = useSearchParams();
  
  const initialExamId = searchParams.get('exam_id')
  const initialQuestionId = searchParams.get('question_id')
  
  const [examId, setExamId] = useState(initialQuestionId ? initialQuestionId : 2);
  const [questionId, setQuestionId] = useState(initialQuestionId ? initialQuestionId : '');
  const [showQuestionView, setShowQuestionView] = useState(true);
  
  return (
    <div>
      <button onClick={()=>setShowQuestionView(true)}>Show Question</button>
      <PopupContainer
        visible={showQuestionView}
        onClose={() => {
          setShowQuestionView(false);
        }}
        content={
          <Question
            examId={examId}
            questionId={questionId}
            onCreate={(nq) => {
              setQuestionId(nq.id);
            }}
            onUpdate={(uq) => {
              
            }}
            onClose={() => {
              setShowQuestionView(false);
            }}
          />
        }
      />
    </div>
  )
}

export default Test;
