import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import PopupContainer from './PopupContainer';
import QuestionSets from './QuestionSets';
import QuestionSetView from './QuestionSetView';

import { 
    getExamsAPI,
    getLiveExamAPI,
    updateLiveExamAPI,
} from '../apis/ExamsApis';

const emptyItem = {
  exam_id: '',
  exam_date: '',
  exam_question_set_id: ''
};


const LiveExam = ({ itemId, onNewItem, onUpdateItem, onClose }) => {
  const [item, setItem] = useState(emptyItem);
  const [isLoading, setIsLoading] = useState(true);
  
  const [exams, setExams] = useState([]);
  
  const [showQuestionSetsPopup, setShowQuestionSetsPopup] = useState(false);
  
  const loadExams = () => {
    getExamsAPI().then((response) => {
      if(response.status == 'ok') {
        setExams(response.data);
      }
    });
  }
  
  useEffect(() => {
      loadExams();
  }, [])
  
  
  const loadItem = (itemId) => {
    setIsLoading(false);
    getLiveExamAPI(itemId).then((response) => {
      if(response.status == 'ok') {
        setItem(response.data);
        setIsLoading(false);
      }
    });
  }
  
  useEffect(() => {
      if(itemId) {
        loadItem(itemId);
      } else {
        setItem(emptyItem);
      }
  }, [itemId])
  
  const toLocalDateString = (dt) => {
      return `${dt.getFullYear()}-${`${dt.getMonth() + 1}`.padStart(2, '0')}-${`${dt.getDate()}`.padStart(2, '0')}`;
  }
  
  const onChangeFieldValue = (field_name, fieldValue) => {
    
    setItem(item => { 
      return { ...item, [field_name]: fieldValue };
    });
    
    if(itemId) {
      const data = {
        exam_id: item.exam_id,
        exam_question_set_id: item.exam_question_set_id,
        exam_date: item.exam_date
      };
      data[field_name] = fieldValue;
      
      updateLiveExamAPI(itemId, data).then((response) => {
        if(response.status == 'ok') {
          onUpdateItem(response.data);
        }
      });
    }
  }
  
  const onCreateItemClick = () => {
    const data = {
      exam_id: item.exam_id,
      exam_date: item.exam_date
    };

    updateLiveExamAPI(itemId, data).then((response) => {
      if(response.status == 'ok') {
        onNewItem(response.data);
      }
    });
  };
  
  const onChangeQuestionSetClick = () => {
    setShowQuestionSetsPopup(true);
  }
  
  const onQuestionSetIdChange = (questionSetId) => {
    onChangeFieldValue('exam_question_set_id', questionSetId);
  }
  
  const onQuestionSetUpdated = () => {
    getLiveExamAPI(itemId).then((response) => {
      if(response.status == 'ok') {
        setItem(response.data);
        onUpdateItem(response.data);
      }
    });
  }
  
  return (
    <div>
      <div 
        className={classNames('content-body')}
      >
        <span onClick={() => onClose()} className={classNames('close-button')}>+</span>
        <div 
          className={classNames('block')}
        >
          <div 
            className={classNames('field')}
          >
            <div
              className={classNames('field__value')}
            >
              <span>Exam </span>
              <select
                  value={item.exam_id}
                  onChange={(e) => onChangeFieldValue('exam_id', e.target.value)}
                  disabled={!!itemId}
                >
                  <option key={'exam_select'} value={''}>Select</option>
                  { exams.map(e => <option key={e.id} value={e.id}>{e.name}</option>) }
                </select>
            </div>
          </div>
          <div 
            className={classNames('field')}
          >
            <span 
              className={classNames('field__name')}
            >Exam Date</span>
            <div
              className={classNames('field__value')}
            >
              <Calendar 
                value={item.exam_date}
                onChange={(value) => onChangeFieldValue('exam_date', toLocalDateString(value))}
                minDate={new Date()}
              />
            </div>
          </div>
          
          { !itemId && <div
              style={{ textAlign: 'center', paddingTop: '10px', marginBottom: '-15px' }}
            >
              <button 
                onClick={() => onCreateItemClick()}
                className={classNames('button-80')}
                role="button"
                disabled={!(item.exam_id && item.exam_date)}
              >Create New Question Set</button>
            </div>
          }
        </div>
        
        <div 
          className={classNames('block')}
          style={{ width: '50vw', flex: 'unset' }}
        >
          <div 
            className={classNames('field')}
          >
            <span 
              className={classNames('field__name', 'center')}
            >
              Question Set{item.exam_question_set_id && ` (ID: ${item.exam_question_set_id})`}
              &nbsp;&nbsp;
              <button 
                onClick={() => onChangeQuestionSetClick()}
                className={classNames('button-80')}
                role="button"
                disabled={!itemId}
              >{ item.exam_question_set_id ? 'Change / Edit' : 'Create / Select' }</button>
            </span>
            <div
              className={classNames('field__value', 'full-width')}
            >
              { item.exam_question_set_id && <QuestionSetView
                  itemId={item.exam_question_set_id}
                  numQuestions={item.num_questions}
                />
              }
            </div>
          </div>
        </div>
        <PopupContainer
          visible={showQuestionSetsPopup}
          onClose={() => {
            setShowQuestionSetsPopup(false);
          }}
          content={
            <QuestionSets 
              examId={item.exam_id}
              questionSetId={item.exam_question_set_id}
              onQuestionSetIdChange={onQuestionSetIdChange}
              onQuestionSetUpdated={onQuestionSetUpdated}
              onClose={() => {
                setShowQuestionSetsPopup(false);
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default LiveExam;
