import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { 
    getBooksAPI,
} from '../apis/ExamsApis';


const PDFBooks = () => {
  const [books, setBooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  
  const navigate = useNavigate();
  
  const loadBooks = () => {
    getBooksAPI().then((response) => {
      if(response.status == 'ok') {
        setBooks(response.data);
        setLoadComplete(true);
      }
    });
  }
  
  useEffect(() => {
      loadBooks();
  }, [])
  
  const openBook = (book_id) => {
    navigate(`/book/${book_id}`);
  };
  
  return (
    <div className='main' style={{"justifyContent": "center"}}>
     <div style={{"width": "1000px"}}>
        <table>
          <thead>
            <tr>
              <th>Exam ID</th>
              <th>Exam Name</th>
              <th>Book ID</th>
              <th>Book Name</th>
              <th>Num. Questions</th>
              <th>Source</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            { books.map(b => {
                return <tr key={b.id}>
                  <td>{b.exam_id}</td>
                  <td>{b.exam_name}</td>
                  <td>{b.id}</td>
                  <td>{b.name}</td>
                  <td>{b.num_quetions}</td>
                  <td>{b.source}</td>
                  <td><button onClick={() => openBook(b.id)}>Edit</button></td>
                </tr>
              }
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PDFBooks;
