import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import { 
    getLiveExamsAPI,
    getExamsAPI,
    deleteLiveExamsAPI,
} from '../apis/ExamsApis';

import PopupContainer from './PopupContainer';
import LiveExam from './LiveExam';

const LiveExams = () => {
  const [liveExams, setLiveExams] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  const [editItemId, setEditItemId] = useState('');
  const [showEditItemView, setShowEditItemView] = useState(false);
  
  const [exams, setExams] = useState([]);
  
  const loadLiveExams = () => {
    getLiveExamsAPI().then((response) => {
      if(response.status == 'ok') {
        const liveExams = response.data.map(de => {
          return { ...de, selected: false };
        });
        setLiveExams(liveExams);
        setLoadComplete(true);
      }
    });
  }
  
  const loadExams = () => {
    getExamsAPI().then((response) => {
      if(response.status == 'ok') {
        setExams(response.data);
      }
    });
  }
  
  useEffect(() => {
      loadLiveExams();
      loadExams();
  }, [])
  
  const onCreateLiveExam = (item) => {
    const liveExam = { ...item, selected: false };
    setLiveExams(liveExams => {
      return [...liveExams, liveExam];
    });
  }
  
  const onUpdateLiveExam = (item) => {
    const liveExam = { ...item, selected: false };
    setLiveExams(liveExams => {
      return liveExams.map(de => {
        if(de.id == item.id) {
          return { ...de, ...item };
        } else {
          return de;
        }
      });
    });
  }
  
  const onSelectItem = (liveexam_id, selected) => {
    setLiveExams(liveExams => {
      return liveExams.map(de => {
        if(de.id == liveexam_id) {
          return { ...de, selected: selected };
        } else {
          return de;
        }
      });
    });
  };
  
  const onDeleteButtonClick = () => {
    const deleteItemIds = liveExams.filter(de => de.selected).map(de => de.id);
    const data = {
      live_exam_ids: deleteItemIds
    };
    deleteLiveExamsAPI(data).then((response) => {
      if(response.status == 'ok') {
        setLiveExams(liveExams => {
          return liveExams.filter(de => !deleteItemIds.includes(de.id));
        });
      }
    });
  };
  
  const onCreateItemClick = () => {
    setEditItemId('');
    setShowEditItemView(true);
  };
  
  const onEditItemClick = (liveexam_id) => {
    setEditItemId(liveexam_id);
    setShowEditItemView(true);
  };
  
  return (
    <div className='dailyexams' style={{"justifyContent": "center"}}>
      <table 
        className={classNames('items')}
      >
        <caption>
          <button 
            onClick={() => onDeleteButtonClick()}
            className={classNames('button-80')}
            role="button"
          >Delete</button>
          &nbsp;&nbsp;
          <button 
            onClick={() => onCreateItemClick()}
            className={classNames('button-80')}
            role="button"
          >Create</button>
        </caption>
        <thead>
          <tr>
            <th></th>
            <th>Exam ID</th>
            <th>Exam Name</th>
            <th>Title</th>
            <th>Exam Date</th>
            <th>Question Set ID</th>
            <th>Num. Questions</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          { liveExams.map(b => {
              return <tr key={b.id}>
                <td><input type="checkbox" onChange={() => onSelectItem(b.id, !b.selected)} checked={b.selected} /></td>
                <td>{b.exam_id}</td>
                <td>{b.exam_name}</td>
                <td>{b.title}</td>
                <td>{b.exam_date}</td>
                <td>{b.exam_question_set_id}</td>
                <td>{b.num_questions}</td>
                <td><button 
                  onClick={() => onEditItemClick(b.id)}
                  className={classNames('button-80')}
                  role="button"
                >Edit</button></td>
              </tr>
            }
          )}
        </tbody>
      </table>
      <PopupContainer
        visible={showEditItemView}
        onClose={() => {
          setShowEditItemView(false);
        }}
        content={
          <LiveExam 
            itemId={editItemId}
            onNewItem={(item) => {
              setEditItemId(item.id);
              onCreateLiveExam(item);
            }}
            onUpdateItem={(item) => {
              onUpdateLiveExam(item);
            }}
            onClose={() => {
              setEditItemId('');
              setShowEditItemView(false);
            }}
          />
        }
      />
    </div>
  );
};

export default LiveExams;
