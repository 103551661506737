import api, { setAPIToken } from './api';


export type LoginResponse = {
  status: string;
  data: Object;
};

export type BookSessionResponse = {
  status: string;
  data: Object;
};

export type LatexResponse = {
  status: string;
  data: string;
};

export type PageResponse = {
  status: string;
  data: Object;
};

export type QuestionResponse = {
  status: string;
  data: Object;
};

export type SaveQuestionResponse = {
  status: string;
  error_message: string;
};

export type SaveImageResponse = {
  status: string;
  data: Object;
};

export type UploadImageResponse = {
  status: string;
  data: Object;
};

export type ExamsResponse = {
  status: string;
  data: [Object];
};

export type BooksResponse = {
  status: string;
  data: [Object];
};

export type DailyExamResponse = {
  status: string;
  data: Object;
};

export type LiveExamResponse = {
  status: string;
  data: Object;
};

export type QuestionSetResponse = {
  status: string;
  data: Object;
};

export type DailyExamsResponse = {
  status: string;
  data: [Object];
};

export type LiveExamsResponse = {
  status: string;
  data: [Object];
};

export type ExamSectionsResponse = {
  status: string;
  data: [Object];
};

export type ExamSectionQuestionsResponse = {
  status: string;
  data: [Object];
};

export type QuestionSetsResponse = {
  status: string;
  data: [Object];
};

export type QuestionsResponse = {
  status: string;
  data: [Object];
};

export type CandidateResponse = {
  status: string;
  data: Object;
};

export type CreateResponse = {
  status: string;
  data: Object;
};


const setTokenAPI = (token: string) => {
  setAPIToken(token);
};

const loginAPI = async (username: string, password: string): Promise<LoginResponse> => {
  const url =  '/api/login';

  const response = await fetch(url, { method: 'post', mode: 'cors', body: JSON.stringify({ 'username': username, 'password': password }) });
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.json()) as LoginResponse;

  return resData;
};

const loadBookSessionAPI = async (bookId: number, questionId: number): Promise<BookSessionResponse> => {
  const queryString = questionId ? `?question_id=${questionId}` : '';
  const url =  `/api/book/${bookId}${queryString}`;

  const response = await api.post(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = response.data as BookSessionResponse;

  return resData;
};

const imageToLatexAPI = async (imageData: string): Promise<LatexResponse> => {
  const url = '/api/latex';

  const response = await api.post(url, { 'image': imageData });
  if (!response) {
    return { 'status': 'error', 'data': '' };
  }

  const resData = (await response.data) as LatexResponse;

  return resData;
};

const getPageAPI = async (pageId: number): Promise<PageResponse> => {
  const url = '/api/page';

  const response = await api.post(url, { 'id': pageId });
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as PageResponse;

  return resData;
};

const getPageByNumberAPI = async (bookId: number, pageNum: number): Promise<PageResponse> => {
  const url = '/api/page';

  const response = await api.post(url, { 'book_id': bookId, 'number': pageNum });
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as PageResponse;

  return resData;
};

const getQuestionAPI = async (questionId: number): Promise<QuestionResponse> => {
  const url = '/api/question';

  const response = await api.post(url, { 'id': questionId });
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionResponse;

  return resData;
};

const getBookQuestionAPI = async (questionId: number): Promise<QuestionResponse> => {
  const url = '/api/bookquestion';

  const response = await api.post(url, { 'id': questionId });
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionResponse;

  return resData;
};

const saveQuestionAPI = async (data: Object): Promise<SaveQuestionResponse> => {
  const url = '/api/savequestion';

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'error_message': 'Unknown Error' };
  }

  const resData = (await response.data) as SaveQuestionResponse;

  return resData;
};

const saveImageAPI = async (bookId: number, imageData: Object): Promise<SaveImageResponse> => {
  const url = '/api/saveimage';
 
  const response = await api.post(url, { 'book_id': bookId, 'image': imageData });
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as SaveImageResponse;

  return resData;
};

const getExamsAPI = async (): Promise<ExamsResponse> => {
  const url = '/api/exams';

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as ExamsResponse;

  return resData;
};

const getExamSectionsAPI = async (examId: number): Promise<ExamSectionsResponse> => {
  const url = `/api/exam/${examId}/sections`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as ExamSectionsResponse;

  return resData;
};

const getRecentQuestionAPI = async (): Promise<QuestionResponse> => {
  const url = '/api/recentquestion';

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionResponse;

  return resData;
};

const getBooksAPI = async (): Promise<BooksResponse> => {
  const url = '/api/books';

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as BooksResponse;

  return resData;
};

const getDailyExamsAPI = async (): Promise<DailyExamsResponse> => {
  const url = '/api/dailyexams';

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as DailyExamsResponse;

  return resData;
};

const getDailyExamAPI = async (id: string): Promise<DailyExamResponse> => {
  const url = `/api/dailyexam/${id}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as DailyExamResponse;

  return resData;
};

const updateDailyExamAPI = async (id: string, data: Object): Promise<DailyExamResponse> => {
  const url = id ? `/api/dailyexam/${id}` : `/api/dailyexam`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as DailyExamResponse;

  return resData;
};

const deleteDailyExamsAPI = async (data: Object): Promise<DailyExamResponse> => {
  const url = `/api/deletedailyexams`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as DailyExamResponse;

  return resData;
};

const getLiveExamsAPI = async (): Promise<LiveExamsResponse> => {
  const url = '/api/liveexams';

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as LiveExamsResponse;

  return resData;
};

const getLiveExamAPI = async (id: string): Promise<LiveExamResponse> => {
  const url = `/api/liveexam/${id}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as LiveExamResponse;

  return resData;
};

const updateLiveExamAPI = async (id: string, data: Object): Promise<LiveExamResponse> => {
  const url = id ? `/api/liveexam/${id}` : `/api/liveexam`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as LiveExamResponse;

  return resData;
};

const deleteLiveExamsAPI = async (data: Object): Promise<LiveExamResponse> => {
  const url = `/api/deleteliveexams`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as LiveExamResponse;

  return resData;
};

const getQuestionSetAPI = async (id: string): Promise<QuestionSetResponse> => {
  const url = `/api/questionset/${id}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionSetResponse;

  return resData;
};

const createQuestionSetAPI = async (data: Object): Promise<QuestionSetResponse> => {
  const url = `/api/questionset`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionSetResponse;

  return resData;
};

const deleteQuestionSetAPI = async (id: string): Promise<QuestionSetResponse> => {
  const url = `/api/questionset/${id}`;

  const response = await api.delete(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionSetResponse;

  return resData;
};

const getBookSectionQuestionsAPI = async (book_id: string, exam_section_id: string): Promise<ExamSectionQuestionsResponse> => {
  const url = `/api/bookquestions/${book_id}/${exam_section_id}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as ExamSectionQuestionsResponse;

  return resData;
};

const getQuestionSetsAPI = async (exam_id: string, eqs_id: string): Promise<QuestionSetsResponse> => {
const url = `/api/questionsets?exam_id=${exam_id}${eqs_id ? `&eqs_id=${eqs_id}` : ''}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as QuestionSetsResponse;

  return resData;
};

const searchQuestionsAPI = async (exam_id: string, exam_section_id: string, search_term: string, page: number): Promise<QuestionsResponse> => {
  
  const encode_search_term = encodeURIComponent(search_term);
  const url = `/api/searchquestions?exam_id=${exam_id}&exam_section_id=${exam_section_id}&search_term=${encode_search_term}&page=${page}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as QuestionsResponse;

  return resData;
};

const addQuestionSetQuestionsAPI = async (exam_qustion_set_id: string, data: Object): Promise<QuestionsResponse> => {
  const url = `/api/addquestionsetquestions/${exam_qustion_set_id}`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': [{}] };
  }

  const resData = (await response.data) as QuestionsResponse;

  return resData;
};

const deleteQuestionSetQuestionsAPI = async (data: Object): Promise<QuestionSetResponse> => {
  const url = `/api/deletequestionsetquestions`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as QuestionSetResponse;

  return resData;
};

const uploadImageAPI = async (formData: FormData): Promise<UploadImageResponse> => {
  const url = '/api/uploadimage';
  
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };

  const response = await api.post(url, formData, config);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as UploadImageResponse;

  return resData;
};

const getCandidateAPI = async (id: string): Promise<CandidateResponse> => {
  const url = `/api/candidate/${id}`;

  const response = await api.get(url);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as CandidateResponse;

  return resData;
};

const sendWhatsAppMessageAPI = async (data: Object): Promise<CreateResponse> => {
  const url = `/api/sendwhatsappmessage`;

  const response = await api.post(url, data);
  if (!response) {
    return { 'status': 'error', 'data': {} };
  }

  const resData = (await response.data) as CreateResponse;

  return resData;
};


export { 
  loginAPI, loadBookSessionAPI, 
  imageToLatexAPI, getPageAPI, getPageByNumberAPI, 
  getQuestionAPI, getBookQuestionAPI, 
  getExamsAPI, getExamSectionsAPI,
  getRecentQuestionAPI, saveQuestionAPI, saveImageAPI,
  getBooksAPI, uploadImageAPI, setTokenAPI,
  getDailyExamsAPI, getDailyExamAPI, updateDailyExamAPI, deleteDailyExamsAPI,
  getLiveExamsAPI, getLiveExamAPI, updateLiveExamAPI, deleteLiveExamsAPI,
  getQuestionSetAPI, createQuestionSetAPI, deleteQuestionSetAPI,
  getQuestionSetsAPI, addQuestionSetQuestionsAPI, deleteQuestionSetQuestionsAPI,
  getBookSectionQuestionsAPI,
  searchQuestionsAPI,
  getCandidateAPI, sendWhatsAppMessageAPI
}
