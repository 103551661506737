import { useState, useRef, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { 
    saveQuestionSolutionAPI,
} from '../apis/ExamsApis';

const Solution = ({ questionId, question, onSave}) => {
  const [currentView, setCurrentView] = useState('text');
  const [solutionText, setSolutionText] = useState(question.solution);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const imageRef = useRef(null);
  
  const textAreaRef = useRef(null);
  const [triggerTextAreaAdjust, setTriggerTextAreaAdjust] = useState(true);
  
  useEffect(() => {
    textAreaAdjust(textAreaRef.current);
  }, [triggerTextAreaAdjust])
  
  function textAreaAdjust(element) {
    element.style.height = "1px";
    if(element.scrollHeight > 100) {
      element.style.height = (15+element.scrollHeight)+"px";
    } else {
      element.style.height = "100px";
    }
  }
  
  useEffect(() => {
    if(question.solution_image) {
      setCurrentView('image');
    } else {
      setCurrentView('text');
    }
  }, [question.solution_image])
  
  function saveAsText() {
    setIsSaving(true);
    const data = { solution: solutionText };
    saveQuestionSolutionAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        onSave(solutionText, "");
        setIsSaving(false);
        setIsEdited(false);
      }
    });
  }
  
  async function saveAsImage() {
    setIsSaving(true);
    const imageData = await htmlToImage.toPng(imageRef.current);
    const data = { solution: solutionText, image_data: imageData };
    saveQuestionSolutionAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        onSave(solutionText, response.data.solution_image);
        setIsSaving(false);
        setIsEdited(false);
        setCurrentView('image');
      }
    });
  }
  
  function onCancel() {
    setSolutionText(question.solution);
    setIsEdited(false);
    setTriggerTextAreaAdjust(flag => !flag);
  }
  
  function onChangeSolutionText(text) {
    setSolutionText(text);
    setIsEdited(text != solutionText);
    setTriggerTextAreaAdjust(flag => !flag);
  }
  
  function getHtml() {
    return <p dangerouslySetInnerHTML={{__html: solutionText.replace(/(?:\r\n|\r|\n)/g, '<br>')}} ></p>
  }
  
  return (
    <div className='seg'>
      <div className={classNames('seg__left')}>
        <div className={classNames('seg__left__btns')}>
          <button onClick={() => setCurrentView('text')} disabled={ (currentView == 'text' || isSaving) ? true : false}>View as Text</button>
          <button onClick={() => setCurrentView('html')} disabled={ (currentView == 'html' || isSaving ) ? true : false}>View as HTML</button>
          <button onClick={() => setCurrentView('image')} disabled={ (currentView == 'image' || !question.solution_image ) ? true : false}>View as Image</button>
        </div>
        <div className={classNames('seg__left__view', currentView=='text' ? 'show' : 'hide')}>
          <textarea
            ref={textAreaRef}
            value={ solutionText }
            onChange={ (e) => onChangeSolutionText(e.target.value) }
            onKeyUp={ (e) => textAreaAdjust(e.target) }
          />
        </div>
        <div 
          className={classNames('seg__left__view', currentView=='image' ? 'show' : 'hide')}
        >
          <img className={classNames('seg__left__view__image')} src={question.solution_image ? `/api/media/images/${question.solution_image}` : ``} alt='not available' />
        </div>
        <div 
          className={classNames('seg__left__view', currentView=='html' ? 'show' : 'hide')}
          ref={imageRef}
        >
          <div className={classNames('seg__left__view__display')}>
            { currentView=='html' ? getHtml() : '' }
          </div>
        </div>
      </div>
      <div 
        className={classNames('seg__right')}
      >
        { (currentView == 'text' || currentView == 'html') && <div>
            { currentView == 'text' && <button onClick={() => saveAsText()} disabled={ (isSaving || !isEdited) ? true : false}>Save as Text</button> }
            { currentView == 'html' && <button onClick={() => saveAsImage()} disabled={ (isSaving || !isEdited) ? true : false}>Save as Image</button> }
          </div>
        }
        { isEdited && <div>
            <button onClick={() => onCancel()} disabled={ isSaving ? true : false}>Cancel</button>
          </div>
        }
        { isSaving && <div>Saving <BeatLoader 
            color="#22AA22"
            loading={true}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> Please wait!</div>
        }
      </div>
    </div>
  );
};

export default Solution;
