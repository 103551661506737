import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import Calendar from 'react-calendar';

import { 
  getQuestionSetAPI,
  deleteQuestionSetQuestionsAPI,
} from '../apis/ExamsApis';

import PopupContainer from './PopupContainer';
import QuestionSearch from './QuestionSearch';
import Question from './Question';

const QuestionSet = ({ questionSetId, examId, onNewItem, questionSetUpdated, onClose }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSearchQuestionsView, setShowSearchQuestionsView] = useState(false);
  
  const [questionId, setQuestionId] = useState('');
  const [showQuestionView, setShowQuestionView] = useState(false);
  
  const loadQuestionSet = (questionSetId) => {
    setIsLoading(false);
    getQuestionSetAPI(questionSetId).then((response) => {
      if(response.status == 'ok') {
        const questions = response.data.questions.map(q => {
          return { ...q, selected: false };
        });
        setQuestions(questions);
        setIsLoading(false);
      }
    });
  }
  
  useEffect(() => {
      if(questionSetId) {
        loadQuestionSet(questionSetId);
      } else {
        setQuestions([]);
      }
  }, [questionSetId])
  
  
  const onSelectItem = (question_id, selected) => {
    setQuestions(questions => {
      return questions.map(q => {
        if(q.id == question_id) {
          return { ...q, selected: selected };
        } else {
          return q;
        }
      });
    });
  };
  
  const onDeleteButtonClick = () => {
    const qsqIds = questions.filter(q => q.selected).map(q => q.id);
    const data = {
      qsq_ids: qsqIds
    };
    deleteQuestionSetQuestionsAPI(data).then((response) => {
      if(response.status == 'ok') {
        setQuestions(questions => {
          return questions.filter(q => !qsqIds.includes(q.id));
        });
      }
    });
    questionSetUpdated();
  };
  
  const onEditButtonClick = (questionId) => {
    setQuestionId(questionId);
    setShowQuestionView(true);
  };
 
  const getQuestionText = (questionText) => {
    if(questionText.length > 100) {
      return questionText.substring(0, 100) + '...';
    } else {
      return questionText;
    }
  }
  
  return (
    <div className='question-set'>
      <table>
        <caption
          style={{ textAlign: 'right' }}
        >
          <a 
            onClick={() => onDeleteButtonClick()}
            className={classNames('delete-button')}
          >Remove Selected Questions from this Question Set</a>
        </caption>
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Question ID</th>
            <th>Section</th>
            <th>Question</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          { questions.map(q => {
              return <tr key={q.id}>
                <td><input type="checkbox" onChange={() => onSelectItem(q.id, !q.selected)} checked={!!q.selected} /></td>
                <td>{q.id}</td>
                <td>{q.question_id}</td>
                <td>{q.section}</td>
                <td>{getQuestionText(q.question)}</td>
                <td><a 
                      onClick={() => onEditButtonClick(q.question_id)}
                      className={classNames('edit-button')}
                    >Edit</a>
                </td>
              </tr>
            }
          )}
        </tbody>
        { questions.length == 0 && <tfoot><tr><td colSpan='100%'>no questions</td></tr></tfoot> }
      </table>
      
      { questionSetId && 
        <div
          style={{ textAlign: 'center', padding: '5px' }}
        >
          <button 
            onClick={() => setShowSearchQuestionsView(true)}
            className={classNames('button-80')}
            role="button"
          >Add Question</button>
        </div>
      }
      
      <PopupContainer
        visible={showSearchQuestionsView}
        onClose={() => {
          setShowSearchQuestionsView(false);
        }}
        content={
          <QuestionSearch 
            examId={examId}
            questionSetId={questionSetId}
            existingQuestions={questions}
            onAddQuestions={(items) => {
              setQuestions(questions => [...questions, ...items]);
              questionSetUpdated();
            }}
            onClose={() => {
              setShowSearchQuestionsView(false);
            }}
          />
        }
      />
      
      <PopupContainer
        visible={showQuestionView}
        onClose={() => {
          setShowQuestionView(false);
        }}
        content={
          <Question
            examId={examId}
            questionId={questionId}
            onUpdate={(uq) => {
              loadQuestionSet(questionSetId);
            }}
            onClose={() => {
              setShowQuestionView(false);
            }}
          />
        }
      />
   </div>
    
  );
};

export default QuestionSet;
