import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CircleLoader } from 'react-spinners';
import { Document, Page, pdfjs } from 'react-pdf';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/4.3.136/pdf.min.mjs`;

import classNames from 'classnames';
import { 
    loadBookSessionAPI, 
    getPageAPI, 
    getPageByNumberAPI,
    getExamSectionsAPI,
    getRecentQuestionAPI, 
    getBookQuestionAPI, 
    saveQuestionAPI,
    saveImageAPI,
    uploadImageAPI,
    getBookSectionQuestionsAPI
} from '../apis/ExamsApis';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

const empty_page = {
  id: '',
  prev_id: null,
  next_id: null,
  number: '',
  num_pages: 0,
  image_url: '',
  text_content: '',
}

const empty_question = {
  id: '',
  book_id: '',
  exam_section_id: '',
  prev_id: null,
  next_id: null,
  original_question_num: '',
  original_page_num: '',
  question: '',
  question_image: '',
  no_options: '',
  option_a: '',
  option_b: '',
  option_c: '',
  option_d: '',
  option_e: '',
  correct_option: '',
  answer: '',
  answer_image: '',
  solution: '',
  solution_image: '',
  svg_store: {}
}

const PDFBook = () => {
  const { id: bookId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [book, setBook] = useState(null);
  const [page, setPage] = useState(empty_page);
  const [examId, setExamId] = useState('');
  const [examName, setExamName] = useState('');
  const [examSections, setExamSections] = useState([]);
  const [question, setQuestion] = useState(empty_question);
  const [questionId, setQuestionId] = useState(empty_question.id);
  const [questionView, setQuestionView] = useState({});
  const [solutionView, setSolutionView] = useState({});
  const [currentQuestionId, setCurrentQuestionId] = useState('');
  const [statusText, setStatusText] = useState('');
  const [latexStatusText, setLatexStatusText] = useState('');
  const [showPageText, setShowPageText] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  
  const [showQuestionImage, setShowQuestionImage] = useState(false);
  const [showSolutionImage, setShowSolutionImage] = useState(false);
  
  const [editNumber, setEditNumber] = useState(0);
  
  const navigate = useNavigate();
  
  const canvasRef = useRef(null);
  const pageRef = useRef(null);
  const contextRef = useRef(null);
  const rectangleRef = useRef(null);
  const cloneRef = useRef(null);
  const backgroundImageRef = useRef(new Image(798, 1032));
  const questionImageRef = useRef(null);
  const solutionImageRef = useRef(null);
  
  const [isDrawing, setIsDrawing] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [latextData, setLatexData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [file, setFile] = useState();
  
  const [goToExamSectionId, setGoToExamSectionId] = useState('');
  const [goToQuestionId, setGoToQuestionId] = useState('');
  const [examSectionQuestions, setExamSectionQuestions] = useState([]);
  
  const loadBookSession = (bookId, questionId) => {
    loadBookSessionAPI(bookId, questionId).then((response) => {
      if(response.status == 'ok') {
        const data = response.data;
        setBook(data.book);
        setExamId(data.book.exam_id);
        setExamName(data.book.exam_name);
        setPage(data.page ? data.page : empty_page);
        let q = data.question;
        if(q) {
          q.question = fixImageSrc(q.question)
          q.option_a = fixImageSrc(q.option_a)
          q.option_b = fixImageSrc(q.option_b)
          q.option_c = fixImageSrc(q.option_c)
          q.option_d = fixImageSrc(q.option_d)
          q.option_e = fixImageSrc(q.option_e)
          q.answer = fixImageSrc(q.answer)
          q.solution = fixImageSrc(q.solution)
        } else {
          q = { ...empty_question, book_id: bookId };
        }
        setQuestion(q);
        setQuestionId(q.id);
        backgroundImageRef.current.src = data.page.image_url;
        setLoadComplete(true);
        setGoToExamSectionId('');
        setGoToQuestionId('');
      } else {
        navigate("/books")
      }
    });
  }
  
  useEffect(() => {
      if(bookId) {
        const questionId = searchParams.get('question_id')
        loadBookSession(bookId, questionId);
      } else {
        navigate("/books");
      }
  }, [bookId])
  
  useEffect(() => {
      if(examId) {
        getExamSectionsAPI(examId).then((response) => {
          if(response.status == 'ok') {
            setExamSections(response.data);
          }
        });
      }
  }, [examId])
  
  const canvasOffSetX = useRef(null);
  const canvasOffSetY = useRef(null);
  const startX = useRef(null);
  const startY = useRef(null);

  useEffect(() => {
    if(loadComplete) {
      const canvas = canvasRef.current;
      
      canvas.width = 798;
      canvas.height = 1032;
  
      const context = canvas.getContext("2d");
      context.lineCap = "round";
      context.strokeStyle = "green";
      context.lineWidth = 2;
      contextRef.current = context;
  
      const canvasOffSet = canvas.getBoundingClientRect();
      
      console.log('canvasOffSet:', canvasOffSet);
      
      canvasOffSetX.current = canvasOffSet.left;
      canvasOffSetY.current = canvasOffSet.top;
    }
  }, [loadComplete]);

  useEffect(() => {
    updateImageViews();
  }, [questionId, editNumber]);

  const startDrawingRectangle = ({nativeEvent}) => {
      nativeEvent.preventDefault();
      nativeEvent.stopPropagation();

      const canvasOffSet = canvasRef.current.getBoundingClientRect();

      console.log('nativeEvent.clientY:', nativeEvent.clientY);
      console.log('canvasOffSet.left:', canvasOffSet.left);
      console.log('canvasOffSet.top:', canvasOffSet.top);
      console.log('pageRef.current.scrollTop:', pageRef.current.scrollTop);

      startX.current = nativeEvent.clientX - canvasOffSet.left;
      startY.current = nativeEvent.clientY - canvasOffSet.top;

      setIsDrawing(true);
  };

  const drawRectangle = ({nativeEvent}) => {
    if (!isDrawing) {
        return;
    }

    nativeEvent.preventDefault();
    nativeEvent.stopPropagation();
    
    const canvasOffSet = canvasRef.current.getBoundingClientRect();

    const newMouseX = nativeEvent.clientX - canvasOffSet.left;
    const newMouseY = nativeEvent.clientY - canvasOffSet.top;

    const rectWidth = newMouseX - startX.current;
    const rectHeight = newMouseY - startY.current;

    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    contextRef.current.strokeRect(startX.current, startY.current, rectWidth, rectHeight);
    
    rectangleRef.current = { start: startX.current, end: startY.current, width: rectWidth, height: rectHeight };
  };

  const stopDrawingRectangle = () => {
    setIsDrawing(false);
    const rect = rectangleRef.current;
    if(rect) {
      cloneRef.current.width = rect.width;
      cloneRef.current.height = rect.height;
      const ctx = cloneRef.current.getContext("2d");
      ctx.reset();
      ctx.drawImage(backgroundImageRef.current, rect.start, rect.end, rect.width, rect.height, 0, 0, rect.width, rect.height);
      const imgData = cloneRef.current.toDataURL();
      setImageData(imgData);
    }
  };
  
  const onParseHandler = () => {
    setIsLoading(true);
    imageToLatexAPI(imageData).then((response) => {
      if(response.status == 'ok') {
        setLatexData(response.data);
        setIsLoading(false);
      } else {
        setLatexData('ERROR:' + response.error_message);
        setIsLoading(false);
      }
    });
  }
  
  const onCopyToClipboard = () => {
    navigator.clipboard.writeText(latextData);
  }
  
  const clearCanvas = () => {
    if(canvasRef.current && contextRef.current) {
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    }
  }
  
  const onPrevPageClick = () => {
    clearCanvas();
    getPageAPI(page.prev_id).then((response) => {
      if(response.status == 'ok') {
        setPage(response.data);
        backgroundImageRef.current.src = response.data.image_url;
      }
    });
  }
  
  const onNextPageClick = () => {
    clearCanvas();
    getPageAPI(page.next_id).then((response) => {
      if(response.status == 'ok') {
        setPage(response.data);
        backgroundImageRef.current.src = response.data.image_url;
      }
    });
  }
  
  const loadPageByNumber = (pageNum) => {
    clearCanvas();
    getPageByNumberAPI(bookId, pageNum).then((response) => {
      if(response.status == 'ok') {
        setPage(response.data);
        backgroundImageRef.current.src = response.data.image_url;
      }
    });
  }
  
  const onPrevQuestionClick = () => {
    getBookQuestionAPI(question.prev_id).then((response) => {
      if(response.status == 'ok') {
        const q = response.data;
        q.question = fixImageSrc(q.question)
        q.option_a = fixImageSrc(q.option_a)
        q.option_b = fixImageSrc(q.option_b)
        q.option_c = fixImageSrc(q.option_c)
        q.option_d = fixImageSrc(q.option_d)
        q.option_e = fixImageSrc(q.option_e)
        q.answer = fixImageSrc(q.answer)
        q.solution = fixImageSrc(q.solution)
        setQuestion(q);
        setQuestionId(q.id);
      }
    });
  }
  
  const onNextQuestionClick = () => {
    if(question.next_id) {
      getBookQuestionAPI(question.next_id).then((response) => {
        if(response.status == 'ok') {
          const q = response.data;
          q.question = fixImageSrc(q.question)
          q.option_a = fixImageSrc(q.option_a)
          q.option_b = fixImageSrc(q.option_b)
          q.option_c = fixImageSrc(q.option_c)
          q.option_d = fixImageSrc(q.option_d)
          q.option_e = fixImageSrc(q.option_e)
          q.answer = fixImageSrc(q.answer)
          q.solution = fixImageSrc(q.solution)
          setQuestion(q);
          setQuestionId(q.id);
        }
      });
    } else {
      const q = { ...empty_question, prev_id: question.id, book_id: bookId  };
      setQuestion(q);
      setQuestionId(q.id);
    }
  }
  
  const onSaveQuestionClick = async () => {
    setStatusText("Saving...");
    setIsSaving(true);
    let questionImageData = null;
    if(showQuestionImage) {
      if(question.question.match(/\<(img|svg)/)) {
        if(!question.question.match(/^\<img src\=['"].*?['"] \/\>$/)) {
          questionImageData = await htmlToImage.toPng(questionImageRef.current);
        }
      }
    }
    
    let solutionImageData = null;
    if(showSolutionImage) {
      if(question.solution.match(/\<(img|svg)/)) {
        if(!question.solution.match(/^\<img src\=['"].*?['"] \/\>$/)) {
          solutionImageData = await htmlToImage.toPng(solutionImageRef.current);
        }
      }
    }
    
    const q = { ...question, question_image_data: questionImageData, solution_image_data: solutionImageData, svg_store: null }; 
    saveQuestionAPI(q).then((response) => {
      if(response.status == 'ok') {
        const q = { ...question, ...response.data };
        setQuestion(q);
        setStatusText("");
      } else {
        setStatusText(response.error_message);
      }
      setIsSaving(false);
    });
  }
  
  const onSaveImageClick = () => {
    setLatexStatusText("Saving...");
    setLatexData("");
    saveImageAPI(bookId, imageData).then((response) => {
      if(response.status == 'ok') {
        const image_url = response.data.image_url;
        setLatexData(`<img src="/api/media/images/${image_url}" />`);
        setLatexStatusText("");
      } else {
        setLatexStatusText(response.error_message);
      }
    });
  }
  
  const handleFieldUpdate = (field, value) => {
    const q = { ...question, [field]: value };
    setQuestion(q);
    setEditNumber(en => en + 1);
  }
  
  const handleNoOptionUpdate = (value) => {
    if(value == true) {
      setQuestion(q => { return { ...q, no_options: value, correct_option: '' }});
    } else {
      setQuestion(q => { return { ...q, no_options: value }});
    }
  }
    
  const onChangeExamSection = (examSectionId) => {
    if(question.exam_section_id != examSectionId) {
      if(examSectionId == '') {
        const q = { ...question, exam_section_id: '' };
        setQuestion(q);
      } else {
        const es = examSections.find(es => es.id == examSectionId);
        const q = { ...question, exam_section_id: examSectionId };
        setQuestion(q);
      }
    }
  }
  
  function handleChange(event) {
    setFile(event.target.files[0])
  }
  
  function handleUpload() {
    setLatexStatusText("Uploading...");
    setLatexData("");
    
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    formData.append('book_id', bookId);
  
    uploadImageAPI(formData).then((response) => {
      if(response.status == 'ok') {
        const image_url = response.data.image_url
        setLatexData(`<img src="/api/media/images/${image_url}" />`);
        setLatexStatusText("");
      } else {
        setLatexStatusText(response.error_message);
      }
    });
  }
  
  const fixImageSrc = (content) => {
    let replacedText;
    replacedText = reactStringReplace(content, /\<img src\=['"](.*?)['"] \/\>/, (match, i) => {
      const url = match.includes('/') ? match : `/api/media/images/${match}`;
      return `<img src='${url}' />`
    });

    return replacedText.join('')
  }
  
  
  const fixSvgInContent = (content) => {
    let replacedContent;
    content = content.trim().replaceAll("\n", "<br />")
    replacedContent = reactStringReplace(content, /(\<svg src\=['"].*?['"] \/\>)/, (match, i) => {
      let filePath = match.match(/\<svg src\=['"](.*?)['"] \/\>/).pop()
      const fileName = filePath.split('/').pop();
      if(question.svg_store.hasOwnProperty(fileName)) {
        return question.svg_store[fileName];
      } else {
        return match;
      }
    });
    return replacedContent.join('')
  }
  
  const updateQuestionImage = () => {
    const view = {
        question: fixSvgInContent(question.question),
        option_a: fixSvgInContent(question.option_a),
        option_b: fixSvgInContent(question.option_b),
        option_c: fixSvgInContent(question.option_c),
        option_d: fixSvgInContent(question.option_d),
        option_e: fixSvgInContent(question.option_e),
    };
    setQuestionView(view);
  }
  
  const updateSolutionImage = () => {
    const view = {
        solution: fixSvgInContent(question.solution),
    };
    setSolutionView(view);
  }
  
  const onShowQuestionImage = () => {
    updateQuestionImage();
    setShowQuestionImage(true);
  }
    
  const onShowSolutionImage = () => {
    updateSolutionImage();
    setShowSolutionImage(true);
  }
  
  const updateImageViews = () => {
    updateQuestionImage();
    updateSolutionImage();
  }
  
  const onChangeGoToExamSection = (examSectionId) => {
    setGoToExamSectionId(examSectionId);
    getBookSectionQuestionsAPI(bookId, examSectionId).then((response) => {
      if(response.status == 'ok') {
        setExamSectionQuestions(response.data);
      }
    });
  }
  
  const onChangeGoToQuestion = (questionId) => {
    setGoToQuestionId(questionId);
    getBookQuestionAPI(questionId).then((response) => {
      if(response.status == 'ok') {
        const q = response.data;
        q.question = fixImageSrc(q.question)
        q.option_a = fixImageSrc(q.option_a)
        q.option_b = fixImageSrc(q.option_b)
        q.option_c = fixImageSrc(q.option_c)
        q.option_d = fixImageSrc(q.option_d)
        q.option_e = fixImageSrc(q.option_e)
        q.answer = fixImageSrc(q.answer)
        q.solution = fixImageSrc(q.solution)
        setQuestion(q);
        setQuestionId(q.id);
      }
    });
  }
  
  const isQuestionImageMissing = () => {
    if(question.question.includes("<img") || question.question.includes("<svg")) {
      if(!question.question_image) {
        return true;
      }
    }
  }
  
  const isSolutionImageMissing = () => {
    if(question.solution.includes("<img") || question.solution.includes("<svg")) {
      if(!question.solution_image) {
        return true;
      }
    }
  }
  
  if(!book) {
    return <div>Please Wait!</div>;
  }
  
  return (
    <div className='main'>
      <div 
        className={classNames('main__left')}
        ref={pageRef}
      >
        <div 
          className={classNames('main__left__header')} 
        > 
          <div>
            <button onClick={() => navigate("/books")}>Home</button>
            &nbsp;&nbsp;{page.book_name}
            &nbsp;&nbsp;{ book.pdf_filename && <a href={`/api/media/books/${book.pdf_filename}`} target="_blank">PDF</a>}
          </div>
          <div 
            className={classNames('buttons')}
            style={{ visibility: page.book_id ? 'visible' : 'hidden'}}
          >
            <button
              className={classNames('prev')}
              onClick={onPrevPageClick}
              disabled={ page.prev_id ? false : true }
            >Prev</button>
            <select
                value={page.number}
                onChange={(e) => loadPageByNumber(e.target.value)}
                style={{"width": "50px"}}
              >
              { book.page_numbers.map(page_number => <option key={page_number} value={page_number}>{page_number}</option>) }
            </select>
            <button
              className={classNames('next')}
              onClick={onNextPageClick}
              disabled={ page.next_id ? false : true }
            >Next</button>
          </div>
          <ul
            style={{ visibility: page.book_id ? 'visible' : 'hidden'}}
          >
              <li 
                className={ classNames({'selected': !showPageText })}
                onClick={() => setShowPageText(false)}
              >PDF</li>
              <li 
                className={ classNames({'selected': showPageText })}
                onClick={() => setShowPageText(true)}
              >TEXT</li>
            </ul>
        </div>
        
        <canvas 
          className={classNames('main__left__canvas', {'show': !showPageText })} 
          style= { page.image_url ? { backgroundImage: `URL(${page.image_url})` } : {}}
          ref={canvasRef}
          onMouseDown={startDrawingRectangle}
          onMouseMove={drawRectangle}
          onMouseUp={stopDrawingRectangle}
          onMouseLeave={stopDrawingRectangle}>
        </canvas>
        
        {
        //<Document file={page.image_url}>
        //  <Page pageNumber={1} />
        //</Document>
        }
        <pre
          className={classNames('main__left__pdftext', {'show': showPageText })} 
        >{page.text_content}</pre>
        <canvas ref={cloneRef} className={classNames('main__left__hidden_canvas')} ></canvas>
      </div>
      <div className={classNames('main__right')}>
        <div 
            className={classNames('main__right__latex')}
        >
          <div 
            className={classNames('main__right__latex__image')}
          >
            { imageData && <img 
                src={imageData} 
              /> 
            }
          </div>
          <div
            className={classNames('main__right__latex__buttons__save')}
          >
            <div
              className={classNames('main__right__latex__buttons')}
            >
              { imageData && <button onClick={onSaveImageClick}>Save</button> }
              { latexStatusText && <>
                <blockquote>{latexStatusText}</blockquote>
              </>}
              { latextData && <>
                <blockquote>{latextData}</blockquote>
                <button onClick={() => onCopyToClipboard()}>Copy</button>
              </>}
            </div>
            <div
              className={classNames('main__right__latex__buttons__upload')}
            >
              <input type="file" onChange={handleChange} />
              <button type="button" onClick={handleUpload}>Upload</button>
            </div>
          </div>
        </div>
        <div>
          Go To: 
          <select
            value={goToExamSectionId}
            onChange={(e) => onChangeGoToExamSection(e.target.value)}
          >
          <option key={'goto_examsection_select'} value={''}>Select</option>
          { examSections.map(es => <option key={es.id} value={es.id}>{es.name}</option>) }
          </select>
          &nbsp;&nbsp;
          <select
            value={goToQuestionId}
            onChange={(e) => onChangeGoToQuestion(e.target.value)}
          >
          <option key={'goto_question_select'} value={''}>Select</option>
          { examSectionQuestions.map(esq => <option key={esq.id} value={esq.id}>{esq.id} - {esq.number} - {esq.text}</option>) }
          </select>
        </div>
        <div 
          className={classNames('main__right__question')}
        >
          <div 
            className={classNames('main__right__question__header')}
          >
            <button 
              className={classNames('prev')}
              onClick={onPrevQuestionClick}
              disabled={ question.prev_id ? false : true }
            >PREV</button>
            <label><b>{ question.id }</b></label>
            { isQuestionImageMissing() && <span className={classNames('missing-red')}>•</span>}
            { isSolutionImageMissing() && <span className={classNames('missing-blue')}>•</span>}
            { !question.is_ok && <span className={classNames('missing')}>⬛</span>}
            <button 
              className={classNames('next')}
              onClick={onNextQuestionClick} 
              disabled={!question.id}
            >{ question.next_id ? 'NEXT' : 'NEW' }</button>
          </div>
          
          <div 
            className={classNames('main__right__question__field')}
          >
            <label>EXAM</label>
            <blockquote>{examName}</blockquote>
          </div>
          
          <div 
            className={classNames('main__right__question__field')}
          >
            <label>SECTION</label>
              <select
                value={question.exam_section_id}
                onChange={(e) => onChangeExamSection(e.target.value)}
                style={{"width": "400px"}}
              >
              <option key={'examsection_select'} value={''}>Select</option>
              { examSections.map(es => <option key={es.id} value={es.id}>{es.name}</option>) }
              </select>
          </div>
          <div 
              className={classNames('main__right__question__field')}
            >
              <label>ORIGINAL QUESTION NUMBER</label>
              <input
                value={ question.original_question_num }
                onChange={ (e) => handleFieldUpdate('original_question_num', e.target.value) }
              />
          </div>
          <div 
              className={classNames('main__right__question__field')}
            >
              <label>ORIGINAL PAGE NUMBER</label>
              <input
                value={ question.original_page_num }
                onChange={ (e) => handleFieldUpdate('original_page_num', e.target.value) }
              />
          </div>
          <div 
            className={classNames('main__right__question__field')}
          >
            <label>QUESTION</label>
            <div 
              className={classNames('main__right__question__switch')}
            >
              { question.question_image && <a href={`/api/media/images/${question.question_image}`} target="_blank">{question.question_image}</a> }
              <button 
                className={classNames('edit')}
                onClick={() => setShowQuestionImage(false)}
                disabled={ !showQuestionImage }
              >TEXT VIEW</button>
              <button 
                className={classNames('view')}
                onClick={() => onShowQuestionImage()} 
                disabled={ showQuestionImage }
              >IMAGE VIEW</button>
            </div>
            <div 
              className={classNames('main__right__question__imageview', showQuestionImage ? 'show' : 'hide')}
              ref={questionImageRef}
            >
              <div>
                <p dangerouslySetInnerHTML={{__html: questionView.question}}></p>
                {!question.no_options && <ol type='A'>
                    { question.option_a  && <li dangerouslySetInnerHTML={{__html: questionView.option_a}} ></li>}
                    { question.option_b  && <li dangerouslySetInnerHTML={{__html: questionView.option_b}} ></li>}
                    { question.option_c  && <li dangerouslySetInnerHTML={{__html: questionView.option_c}} ></li>}
                    { question.option_d  && <li dangerouslySetInnerHTML={{__html: questionView.option_d}} ></li>}
                    { question.option_e  && <li dangerouslySetInnerHTML={{__html: questionView.option_e}} ></li>}
                  </ol>
                }
              </div>
            </div>
            <div className={classNames(showQuestionImage ? 'hide' : 'show')}>
              <textarea
                rows={8}
                value={ question.question }
                onChange={ (e) => handleFieldUpdate('question', e.target.value) }
              />
            </div>
          </div>
          <div 
            className={classNames('main__right__question__field')}
          >
            <label>NO OPTIONS</label>
            <ul>
              <li 
                className={ classNames({'selected': question.no_options }, 'long')}
                onClick={() => handleNoOptionUpdate(true)}
              >TRUE</li>
              <li 
                className={ classNames({'selected': !question.no_options }, 'long')}
                onClick={() => handleNoOptionUpdate(false)}
              >FALSE</li>
            </ul>
          </div>
          
          <div className={classNames({'disabled': question.no_options})}>
            <div 
              className={classNames('main__right__question__field')}
            >
              <label className={classNames({'disabled': question.no_options})}>OPTION A</label>
              <textarea
                rows={ question.option_a.length > 70 ? 2 : 1 }
                value={ question.option_a }
                onChange={ (e) => handleFieldUpdate('option_a', e.target.value) }
                disabled={question.no_options}
              />
            </div>
            
            <div 
              className={classNames('main__right__question__field')}
            >
              <label className={classNames({'disabled': question.no_options})}>OPTION B</label>
              <textarea
                rows={ question.option_b.length > 70 ? 2 : 1 }
                value={ question.option_b }
                onChange={ (e) => handleFieldUpdate('option_b', e.target.value) }
                disabled={question.no_options}
              />
            </div>
            
            <div 
              className={classNames('main__right__question__field')}
            >
              <label className={classNames({'disabled': question.no_options})}>OPTION C</label>
              <textarea
                rows={ question.option_c.length > 70 ? 2 : 1 }
                value={ question.option_c }
                onChange={ (e) => handleFieldUpdate('option_c', e.target.value) }
                disabled={question.no_options}
              />
            </div>
            
            <div 
              className={classNames('main__right__question__field')}
            >
              <label className={classNames({'disabled': question.no_options})}>OPTION D</label>
              <textarea
                rows={ question.option_d.length > 70 ? 2 : 1 }
                value={ question.option_d }
                onChange={ (e) => handleFieldUpdate('option_d', e.target.value) }
                disabled={question.no_options}
              />
            </div>
            
            <div 
              className={classNames('main__right__question__field')}
            >
              <label className={classNames({'disabled': question.no_options})}>OPTION E</label>
              <textarea
                rows={ question.option_e.length > 70 ? 2 : 1 }
                value={ question.option_e }
                onChange={ (e) => handleFieldUpdate('option_e', e.target.value) }
                disabled={question.no_options}
              />
            </div>
          </div>
        
          <div 
            className={classNames('main__right__question__field')}
            disabled={question.no_options}
          >
            <label className={classNames({'disabled': question.no_options})}>CORRECT OPTION</label>
            <ul>
              <li 
                className={ classNames({'selected': question.correct_option == 'A', 'disabled': question.no_options})}
                onClick={() => { if(!question.no_options) { handleFieldUpdate('correct_option', 'A')}}}
              >A</li>
              <li 
                className={ classNames({'selected': question.correct_option == 'B', 'disabled': question.no_options })}
                onClick={() => { if(!question.no_options) { handleFieldUpdate('correct_option', 'B')}}}
              >B</li>
              <li 
                className={ classNames({'selected': question.correct_option == 'C', 'disabled': question.no_options })}
                onClick={() => { if(!question.no_options) { handleFieldUpdate('correct_option', 'C')}}}
              >C</li>
              <li 
                className={ classNames({'selected': question.correct_option == 'D', 'disabled': question.no_options })}
                onClick={() => { if(!question.no_options) { handleFieldUpdate('correct_option', 'D')}}}
              >D</li>
              <li 
              className={ classNames({'selected': question.correct_option == 'E', 'disabled': question.no_options })}
                onClick={() => { if(!question.no_options) { handleFieldUpdate('correct_option', 'E')}}}
              >E</li>
            </ul>
          </div>
          
          <div 
            className={classNames('main__right__question__field', {'disabled': !question.no_options})}
          >
            <label className={classNames({'disabled': !question.no_options})}>ANSWER</label>
            <textarea
              rows={3}
              value={ question.answer }
              onChange={ (e) => handleFieldUpdate('answer', e.target.value) }
              disabled={!question.no_options}
            />
          </div>
          
          <div 
            className={classNames('main__right__question__field')}
          >
            <label>Solution</label>
            <div 
              className={classNames('main__right__question__switch')}
            >
              { question.solution_image && <a href={`/api/media/images/${question.solution_image}`} target="_blank">{question.solution_image}</a> }
              <button 
                className={classNames('edit')}
                onClick={() => setShowSolutionImage(false)}
                disabled={ !showSolutionImage }
              >TEXT VIEW</button>
              <button 
                className={classNames('view')}
                onClick={() => onShowSolutionImage()} 
                disabled={ showSolutionImage }
              >IMAGE VIEW</button>
            </div>
            <div 
              className={classNames('main__right__question__imageview', showSolutionImage ? 'show' : 'hide')}
              ref={solutionImageRef}
            >
              <p dangerouslySetInnerHTML={{__html: solutionView.solution}}></p>
            </div>
           
            <div className={classNames(showSolutionImage ? 'hide' : 'show')}>
              <textarea
                rows={10}
                value={ question.solution }
                onChange={ (e) => handleFieldUpdate('solution', e.target.value) }
              />
            </div>
          </div>
          <div 
            className={classNames('main__right__question__button')}
          >
            <button onClick={onSaveQuestionClick} disabled={isSaving} >Save</button>
            <label>{statusText}</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PDFBook;
