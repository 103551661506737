import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';

import { useCookies } from 'react-cookie';

import { 
    loginAPI,
    setTokenAPI
} from '../apis/ExamsApis';

export default function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [cookies, setCookie] = useCookies(['token']);
  
  const handleSubmit = e => {
    e.preventDefault();
    
    loginAPI(username, password).then((response) => {
      if(response.status == 'ok') {
        setCookie('token', response.data.token);
        setTokenAPI(response.data.token);
      } else {
        setErrorMessage(response.error_message);
      }
    });
  }
  
  return(
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input type="text" onChange={e => setUserName(e.target.value)}/>
        </label>
        <label>
          <p>Password</p>
          <input type="password" onChange={e => setPassword(e.target.value)}/>
        </label>
        <div>
          <button type="submit">Submit</button>
        </div>
        <div>{errorMessage}</div>
      </form>
    </div>
  )
}
