import { useState, useRef, useEffect } from 'react';
import classNames from 'classnames';

const WhatsApp = () => {
  return (
    <div className='main' style={{"justifyContent": "center"}}>
      <div style={{"width": "1000px", "height": "500px"}}>
          <a 
            style={{"width": "1000px", "height": "500px"}}
          >WhatsApp</a>
      </div>
    </div>
  );
};

export default WhatsApp;
