import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import ComprehensionSegment from './ComprehensionSegment';

import { 
    saveComprehensionSegmentAPI,
} from '../apis/ExamsApis';

const empty_segment = {
  id: null,
  text: '',
  image: '',
  key: ''
};


const ComprehensionSegments = ({comprehensionId, segments}) => {
  const [segmentsList, setSegmentsList] = useState(segments);
  
  useEffect(() => {
    console.log('segments REFRESH');
    setSegmentsList(segments);
  }, [segments])
  
  const onSegmentSave = (segment, isNew) => {
    setSegmentsList((segments) => {
      if(!isNew) {
        return segments.map((seg) => {
          if(seg.id == segment.id) {
            return segment;
          } else {
            return seg;
          }
        })
      } else {
        return [ ...segments, segment ];
      }
    });
  }
  
  const onSegmentDelete = (segmentId) => {
    setSegmentsList((segments) => {
        return segments.filter((seg) => seg.id != segmentId)
    });
  }
  
  const onAddNewSegment = () => {
    const data = { id: null, text: "", image: null };
    saveComprehensionSegmentAPI(comprehensionId, data).then((response) => {
      if(response.status == 'ok') {
        const isNew = true;
        onSegmentSave(response.data, isNew);
      }
    });
  }
  
  return (
    <div className='segs'>
      { segmentsList.map((segment, index) => <ComprehensionSegment 
          key={`seg-${segment.id}`} 
          comprehensionId={comprehensionId} 
          segment={segment} 
          onSave={onSegmentSave}
          onDelete={onSegmentDelete}
          isDeletable={index > 0}
         />) 
      }
      <div className={classNames('segs__add_seg')}>
          <a onClick={onAddNewSegment}><span>+</span> Add new segment</a>
      </div>
    </div>
  );
};

export default ComprehensionSegments;
