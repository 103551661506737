import axios from 'axios';


const api = axios.create({
   baseURL: '//play.quaquiz.com',
   });

let accessToken = '';
api.interceptors.request.use(config => {
  
   config.headers.Authorization = `Bearer ${accessToken}`;

   return config;
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 403 || error.response.status == 401) {
        window.location.href = "/logout";
    }
    return error;
});

export default api;

const setAPIToken = (token) => {
  accessToken = token;
}

export { 
  setAPIToken
}