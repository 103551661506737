import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';

import { 
  getExamSectionsAPI,
  getQuestionAPI, 
  saveQuestionAPI,
} from '../apis/ExamsApis';

const empty_question = {
  id: '',
  book_id: '',
  exam_name: '',
  exam_section_id: '',
  original_question_num: '',
  question: '',
  question_segments: [],
  question_image_url: '',
  no_options: '',
  option_a: '',
  option_b: '',
  option_c: '',
  option_d: '',
  option_e: '',
  correct_option: '',
  answer: '',
  answer_image_url: '',
  solution: '',
  solution_image_url: '',
  svg_store: {}
}

const Question = ({ examId, questionId, onNext, onPrev, onCreate, onUpdate, onClose }) => {
  
  const [question, setQuestion] = useState(empty_question);
  const [examSections, setExamSections] = useState([]);
  
  const [isLoading, setIsLoading] = useState(false);
  const [loadComplete, setLoadComplete] = useState(false);
  
  const [isPreviewMode, setIsPreviewMode] = useState(true);
  
  const [isSaving, setIsSaving] = useState(false);
  const [statusText, setStatusText] = useState('');
  
  const loadExamSections = (examId) => {
    getExamSectionsAPI(examId).then((response) => {
      if(response.status == 'ok') {
        setExamSections(response.data);
      }
    });
  }
  
  useEffect(() => {
    setQuestion(empty_question);
    setStatusText('');
    if(examId) {
      loadExamSections(examId);
    }
  }, [examId])
  
  
  const loadQuestion = (questionId) => {
    getQuestionAPI(questionId).then((response) => {
      if(response.status == 'ok') {
        const q = response.data;
        q.question = fixImageSrc(q.question)
        q.option_a = fixImageSrc(q.option_a)
        q.option_b = fixImageSrc(q.option_b)
        q.option_c = fixImageSrc(q.option_c)
        q.option_d = fixImageSrc(q.option_d)
        q.option_e = fixImageSrc(q.option_e)
        q.answer = fixImageSrc(q.answer)
        q.solution = fixImageSrc(q.solution)
        setQuestion(q);
      }
    });
  }
  
  const fixImageSrc = (content) => {
    let replacedText;
    replacedText = reactStringReplace(content, /\<img src\=['"](.*?)['"] \/\>/, (match, i) => {
      const url = match.includes('/') ? match : `/api/media/images/${match}`;
      return `<img src='${url}' />`
    });

    return replacedText.join('')
  }
  
  
  const fixSvgInContent = (content) => {
    let replacedContent;
    content = content.trim().replaceAll("\n", "<br />")
    replacedContent = reactStringReplace(content, /(\<svg src\=['"].*?['"] \/\>)/, (match, i) => {
      let filePath = match.match(/\<svg src\=['"](.*?)['"] \/\>/).pop()
      const fileName = filePath.split('/').pop();
      if(question.svg_store.hasOwnProperty(fileName)) {
        return question.svg_store[fileName];
      } else {
        return match;
      }
    });
    return replacedContent.join('')
  }
  
  useEffect(() => {
    if(questionId) {
      if(questionId != question.id) {
        loadQuestion(questionId);
      }
    } else {
      setQuestion(empty_question);
    }
  }, [questionId])
  
  const onChangeExamSectionId = (es_id) => {
    handleFieldUpdate("exam_section_id", es_id);
  }
  
  const handleFieldUpdate = (field, value) => {
    const q = { ...question, [field]: value };
    setQuestion(q);
    setStatusText("");
  }
  
  const handleNoOptionUpdate = (value) => {
    handleFieldUpdate('no_options', value);
  }
  
  const onSaveQuestionClick = async () => {
    setStatusText("");
    setIsSaving(true);
    let questionImageData = null;
    if(isPreviewMode) {
      if(question.question.match(/\<(img|svg)/)) {
        if(!question.question.match(/^\<(img|svg) src\=['"].*?['"] \/\>$/)) {
          questionImageData = await htmlToImage.toPng(questionImageRef.current);
        }
      }
    }
    
    let solutionImageData = null;
    if(isPreviewMode) {
      if(question.solution.match(/\<(img|svg)/)) {
        if(!question.solution.match(/^\<(img|svg) src\=['"].*?['"] \/\>$/)) {
          solutionImageData = await htmlToImage.toPng(solutionImageRef.current);
        }
      }
    }
    
    const q = { ...question, question_image_data: questionImageData, solution_image_data: solutionImageData, svg_store: null }; 
    saveQuestionAPI(q).then((response) => {
      if(response.status == 'ok') {
        const q = { ...question, ...response.data };
        setQuestion(q);
        if(questionId) {
          onUpdate(q);
          setStatusText("Updated");
        } else {
          setTimeout(() => onCreate(q), 1000);
          setStatusText("Created");
        }
      } else {
        setStatusText(response.error_message);
      }
      setIsSaving(false);
    });
  }
  
  return (
  
    <div 
      className={classNames('question-view')}
    >
      <span onClick={() => onClose()} className={classNames('close-button')}>+</span>
      { question.book_id && <div 
          className={classNames('question-field')}
        >
          <label>PDF BOOK:</label>
          <p>{question.book_name}
            &nbsp;&nbsp;
            <a href={`/book/${question.book_id}?question_id=${question.id}`} target='_blank'>
              Edit in Book UI
            </a>
          </p>
        </div>
      }
      <div 
        className={classNames('question-field')}
      >
        <label>EXAM SECTION</label>
          <select
            value={question.exam_section_id}
            onChange={(e) => onChangeExamSectionId(e.target.value)}
            style={{"width": "400px"}}
          >
            <option key={'examsection_select'} value={''}>Select</option>
          { examSections.map(es => <option key={es.id} value={es.id}>{es.name}</option>) }
          </select>
      </div>
      <div 
        className={classNames('question-field')}
      >
        <label>QUESTION</label>
        <textarea
          rows={8}
          value={ question.question }
          onChange={ (e) => handleFieldUpdate('question', e.target.value) }
        />
      </div>
      <div 
        className={classNames('question-field')}
      >
        <label>NO OPTIONS</label>
        <input type="checkbox" onChange={() => handleNoOptionUpdate(!question.no_options)} checked={!!question.no_options} />
      </div>
      
      <div className={classNames({'disabled': question.no_options})}>
        <div 
          className={classNames('question-field')}
        >
          <label className={classNames({'disabled': question.no_options})}>OPTION A</label>
          <textarea
            rows={ question.option_a.length > 70 ? 2 : 1 }
            value={ question.option_a }
            onChange={ (e) => handleFieldUpdate('option_a', e.target.value) }
            disabled={question.no_options}
          />
        </div>
        
        <div 
          className={classNames('question-field')}
        >
          <label className={classNames({'disabled': question.no_options})}>OPTION B</label>
          <textarea
            rows={ question.option_b.length > 70 ? 2 : 1 }
            value={ question.option_b }
            onChange={ (e) => handleFieldUpdate('option_b', e.target.value) }
            disabled={question.no_options}
          />
        </div>
        
        <div 
          className={classNames('question-field')}
        >
          <label className={classNames({'disabled': question.no_options})}>OPTION C</label>
          <textarea
            rows={ question.option_c.length > 70 ? 2 : 1 }
            value={ question.option_c }
            onChange={ (e) => handleFieldUpdate('option_c', e.target.value) }
            disabled={question.no_options}
          />
        </div>
        
        <div 
          className={classNames('question-field')}
        >
          <label className={classNames({'disabled': question.no_options})}>OPTION D</label>
          <textarea
            rows={ question.option_d.length > 70 ? 2 : 1 }
            value={ question.option_d }
            onChange={ (e) => handleFieldUpdate('option_d', e.target.value) }
            disabled={question.no_options}
          />
        </div>
        
        <div 
          className={classNames('question-field')}
        >
          <label className={classNames({'disabled': question.no_options})}>OPTION E</label>
          <textarea
            rows={ question.option_e.length > 70 ? 2 : 1 }
            value={ question.option_e }
            onChange={ (e) => handleFieldUpdate('option_e', e.target.value) }
            disabled={question.no_options}
          />
        </div>
      </div>
    
      <div 
        className={classNames('question-field')}
        disabled={question.no_options}
      >
        <label className={classNames({'disabled': question.no_options})}>CORRECT OPTION</label>
        <select
            value={question.correct_option.toUpperCase()}
            onChange={(e) => handleFieldUpdate('correct_option', e.target.value)}
            disabled={question.no_options}
          >
            <option key={'correctoption_select'} value={''}>Select</option>
            { ['A', 'B', 'C', 'D', 'E'].map(op => <option key={op} value={op}>{op}</option>) }
        </select>
      </div>
      
      <div 
        className={classNames('question-field', {'disabled': !question.no_options})}
      >
        <label className={classNames({'disabled': !question.no_options})}>ANSWER</label>
        <textarea
          rows={3}
          value={ question.answer }
          onChange={ (e) => handleFieldUpdate('answer', e.target.value) }
          disabled={!question.no_options}
        />
      </div>
      
      <div 
        className={classNames('question-field')}
      >
        <label>Solution</label>
        <textarea
          rows={10}
          value={ question.solution }
          onChange={ (e) => handleFieldUpdate('solution', e.target.value) }
        />
      </div>
      <div 
        style={{ textAlign: 'center', padding: '10px', backgroundColor: '#e1e1e1', marginBottom: '17px' }}
      >
        <button 
          onClick={onSaveQuestionClick}
          className={classNames('button-80')}
          role="button"
          disabled={isSaving}
        >{ isSaving ? questionId ? 'Updating...' : 'Creating...' : questionId ? 'Update' : 'Create' }</button>
        <label>{statusText}</label>
      </div>
    </div>
  )
  
};

export default Question;
