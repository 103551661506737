import classNames from 'classnames';

const PopupContainer = ({ content, visible, onClose }) => {
  return (
    <div 
      className={classNames('popup', visible ? 'popup-show' : 'popup-hide')}
      onClick={onClose}
    >
      <div 
        className={classNames('popup-content')}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        { content }
      </div>
    </div>
  )
}

export default PopupContainer;