import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import classNames from 'classnames';
import Calendar from 'react-calendar';

import { 
  getQuestionSetAPI,
  deleteQuestionSetQuestionsAPI,
} from '../apis/ExamsApis';

const QuestionSetView = ({ itemId, numQuestions }) => {
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const loadQuestionSet = (itemId) => {
    setIsLoading(false);
    getQuestionSetAPI(itemId).then((response) => {
      if(response.status == 'ok') {
        const questions = response.data.questions.map(q => {
          return { ...q, selected: false };
        });
        setQuestions(questions);
        setIsLoading(false);
      }
    });
  }
  
  useEffect(() => {
      if(itemId) {
        loadQuestionSet(itemId);
      } else {
        setQuestions([]);
      }
  }, [itemId, numQuestions])
  
  const getQuestionText = (questionText) => {
    if(questionText.length > 100) {
      return questionText.substring(0, 100) + '...';
    } else {
      return questionText;
    }
  }
  
  return (
    <div className='question-set'>
      <table>
        <thead>
          <tr>
            <th>Question ID</th>
            <th>Section</th>
            <th>Question</th>
          </tr>
        </thead>
        <tbody>
          { questions.map(q => {
              return <tr key={q.id}>
                <td>{q.question_id}</td>
                <td>{q.section}</td>
                <td>{getQuestionText(q.question)}</td>
              </tr>
            }
          )}
        </tbody>
      </table>
   </div>
    
  );
};

export default QuestionSetView;
