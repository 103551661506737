import { useState, useRef, useEffect } from 'react';
import reactStringReplace from 'react-string-replace';
import * as htmlToImage from 'html-to-image';
import { BeatLoader } from 'react-spinners';

import classNames from 'classnames';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import { 
    saveQuestionCorrecOptionAPI,
} from '../apis/ExamsApis';

const CorrectOption = ({ questionId, question, onSave}) => {
  const [correctOption, setCorrectOption] = useState(question.correct_option);
  const [isEdited, setIsEdited] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  function save() {
    setIsSaving(true);
    const data = { correct_option: correctOption };
    saveQuestionCorrecOptionAPI(questionId, data).then((response) => {
      if(response.status == 'ok') {
        onSave(correctOption);
        setIsSaving(false);
        setIsEdited(false);
      }
    });
  }
  
  function onCancel() {
    setCorrectOption(question.correct_option);
    setIsEdited(false);
  }
  
  function onChangeCorrectOption(value) {
    setCorrectOption(value);
    setIsEdited(value != question.correct_option);
  }
  
  return (
    <div 
      className='seg'
      style={{ minHeight: 'initial' }}
    >
      <div 
        className={classNames('seg__left')}
      >
        <select
          value={correctOption}
          onChange={(e) => onChangeCorrectOption(e.target.value)}
        >
          <option key={'na'} value={''}>Select</option>
          <option key={'A'} value={'A'}>A</option>
          <option key={'B'} value={'B'}>B</option>
          <option key={'C'} value={'C'}>C</option>
          <option key={'D'} value={'D'}>D</option>
          <option key={'E'} value={'E'}>E</option>
        </select>
      </div>
      <div 
        className={classNames('seg__right')}
        style={{ paddingTop: '5px' }}
      >
        <div>
          <button onClick={() => save()} disabled={ isSaving || !isEdited ? true : false}>Save</button>
        </div>
        { isEdited && <div>
            <button onClick={() => onCancel()} disabled={ isSaving ? true : false}>Cancel</button>
          </div>
        }
        { isSaving && <div>Saving <BeatLoader 
            color="#22AA22"
            loading={true}
            size={5}
            aria-label="Loading Spinner"
            data-testid="loader"
          /> Please wait!</div>
        }
      </div>
    </div>
  );
};

export default CorrectOption;
