import { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import QuestionEditor from './QuestionEditor';

const QuestionsEditor = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [questionId, setQuestionId] = useState('');
  
  console.log('questionId:', questionId);
  
  useEffect(() => {
    const questionId = searchParams.get('id')
    setQuestionId(questionId);
  }, [])
  
  return <QuestionEditor questionId={questionId} />
};
  
export default QuestionsEditor;